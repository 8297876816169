import React, {useContext, useEffect, useState} from "react";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import MyContext from "../../../MyContext";
import {useNavigate} from "react-router-dom";

export default function SectionList() {
    const [rows, setRows] = useState([]);
    const {setEditing} = useContext(MyContext);
    const navigate = useNavigate();

    const editObj = (id) => {
        const found = rows.find(obj => obj.id === id);
        setEditing(found);
        navigate('/admin/sections/create');
    }

    const cols = [
        {title: "Name", internal: 'name'},
        {title: "Description", internal: 'description'},
        {title: "Display", internal: 'display', isBoolean: true},
        {
            title: "Actions",
            buttons: [
                {
                    icon: faPenToSquare,
                    color: "#9EA3AC",
                    onClick: editObj
                }
            ]
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'section'}
                    pull={setRows}
                    noPagination={true}
                    title={'sections'}
                    cols={cols}
                    rows={rows}
                    withIndex={true}
                />
            </div>
        </div>
    );
}