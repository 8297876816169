function formatPrice(number) {
  const number_to_string = number?.toString();
  let integer = parseInt(number_to_string);
  if (isNaN(integer)) integer = 0;
  // Convert the number to a string and reverse it
  const reversedNumberString = String(integer).split("").reverse().join("");

  // Use a regular expression to add a space every 3 digits
  const formattedNumber = reversedNumberString
    .replace(/(\d{3})/g, "$1 ")
    .trim();

  // Reverse it back to its original order
  return formattedNumber.split("").reverse().join("");
}

function getDiscountPercentage(price, promotion_price) {
  const discount = Math.floor(((price - promotion_price) / price) * 100);
  return discount;
}

function formatDateYMD(date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function formatDateToDayMonthYear(dateString) {
  const date = new Date(dateString);

  // Options pour formater la date
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };

  // Formater la date
  return date.toLocaleDateString("fr-FR", options);
}

export {
  formatPrice,
  getDiscountPercentage,
  formatDateToDayMonthYear,
  formatDateYMD,
};
