import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  DateInput,
  PrimaryButton,
  RadioInput,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";

export default function StockCreate() {
  const [originalQuantity, setOriginalQuantity] = useState(0);
  const [form, setForm] = useState({});
  const [showReason, setShowReason] = useState(false);
  const { editing } = useContext(MyContext);

  useEffect(() => {
    if (editing.warehouse) {
      editing.warehouse = editing.warehouse.id;
      //   editing.quantity = editing.quantity - editing.sold;
    }

    setOriginalQuantity(editing.quantity);

    setForm(editing);
  }, []);

  const back = () => {
    window.location.replace("/admin/stock");
  };

  useEffect(() => {
    if (originalQuantity !== form.quantity) {
      setShowReason(true);
    } else {
      setShowReason(false);
    }
  }, [form, originalQuantity]);

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    console.log(form);

    if (editing) {
      axiosInstance
        .put("stock/" + editing.id + "/", formData)
        .then((response) => {
          back();
        });
    } else {
      axiosInstance.post("stock/", formData).then((response) => {
        back();
      });
    }
  };

  console.log({ editing });

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Edit Batch</div>
          <div>Edit your batch</div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <div style={{ fontWeight: "500" }}>Product:</div>
            <div className={"pb-3"}>{form.product?.name}</div>
            <TextInput name={"quantity"} setForm={setForm} form={form} />
            <div style={{ display: "none" }}>
              Original Quantity: {originalQuantity}
            </div>
            <div style={{ display: "none" }}>
              Original Quantity: {form.quantity}
            </div>
            {showReason && (
              <TextInput name={"reason"} form={form} setForm={setForm} />
            )}
            <DateInput
              name={"Expiry Date"}
              internalName={"expiry_date"}
              setForm={setForm}
              form={form}
            />
            <TextInput
              name={"Total Cost"}
              internalName={"total_cost"}
              setForm={setForm}
              form={form}
            />
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={editing ? "Update" : "Add Stock"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
