import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import modalstyles from "../../components/modal/modal.module.css";
import { Modal as BootStrapModal } from "react-bootstrap";
import purchaseStyles from "./purchase.module.css";

export default function PurchaseOrderManual() {
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [form, setForm] = useState({});
  const [timer, setTimer] = useState(null);
  const [foundProducts, setFoundProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let newSubtotal = 0;
    selectedProducts.forEach((product) => {
      newSubtotal += product.total;
    });
    setSubtotal(newSubtotal);
  }, [selectedProducts]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (form["product_name"]?.trim() !== "") {
      const newTimer = setTimeout(() => {
        axiosInstance
          .get(
            `product/?max_query=5&no_combos=${true}&name=` +
              form["product_name"]
          )
          .then((response) => {
            const found = response.data.results;
            if (found.length === 1) {
              const only_product_found = found[0];
              setSelectedProducts([
                ...selectedProducts,
                {
                  id: only_product_found.id,
                  name: only_product_found.name,
                  cost: only_product_found.cost,
                  packaging: 1,
                  quantity: only_product_found.packaging,
                  originalPackaging: only_product_found.packaging,
                  total: only_product_found.cost * only_product_found.packaging,
                },
              ]);
              setFoundProducts([]);
              setForm({ ...form, product_name: "" });
            } else {
              let data = response.data.results;
              data.forEach((product) => {
                let totalCost = product.cost * product.packaging;
                product.total = totalCost;
              });
              setFoundProducts(response.data.results);
            }
          });
      }, 400);

      setTimer(newTimer);
    }
  }, [form["product_name"]]);

  useEffect(() => {
    axiosInstance.get("warehouse/").then((response) => {
      setWarehouses(response.data);
    });

    axiosInstance.get("biller/").then((response) => {
      setSuppliers(response.data);
    });
  }, []);

  const back = () => {
    window.location.replace("/admin/purchase_order");
  };

  const submit = () => {
    setLoading(true);
    if (
      subtotal < 1 ||
      form.warehouse === undefined ||
      form.supplier === undefined ||
      selectedProducts.length < 1
    ) {
      setLoading(false);
      setShowError(true);
      return;
    }

    const new_form = {
      biller_id: form.supplier,
      warehouse_id: form.warehouse,
      products: selectedProducts,
    };
    axiosInstance.post("purchase_order/", new_form).then((response) => {
      setLoading(false);
      back();
    });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <BootStrapModal show={showError} centered={true}>
        <div className={modalstyles.header}>
          <div>Please select a warehouse and supplier</div>
        </div>

        <div className={modalstyles.footer}>
          <SecondaryButton onClick={() => setShowError(false)} name={"Back"} />
        </div>
      </BootStrapModal>

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Manual Purchase</div>
          <div></div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <SelectInput
              name={"warehouse"}
              form={form}
              setForm={setForm}
              options={warehouses}
            />
            <SelectInput
              name={"supplier"}
              form={form}
              setForm={setForm}
              options={suppliers}
            />
          </div>
          <div className={`${styles.createContainer} p-4`}>
            <TextInput
              form={form}
              setForm={setForm}
              name={"Product"}
              internalName={"product_name"}
            />
            <div>
              {foundProducts?.map((product, index) => (
                <div
                  key={index}
                  className={
                    "d-flex mb-2 px-2 py-2 align-items-center justify-content-between"
                  }
                  style={{
                    borderRadius: "6px",
                    border: "1px #ccc solid",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedProducts([
                      ...selectedProducts,
                      {
                        id: product.id,
                        name: product.name,
                        cost: product.cost,
                        packaging: 1,
                        quantity: product.packaging,
                        originalPackaging: product.packaging,
                        total: product.cost * product.packaging,
                      },
                    ]);
                    setFoundProducts([]);
                    setForm({ ...form, product_name: "" });
                  }}
                >
                  <div>{product.name}</div>
                  <FontAwesomeIcon icon={faPlus} color={"#009F7F"} />
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.createContainer} p-4`}>
            <div className={"d-flex"} style={{ overflowX: "scroll" }}>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Packaging</th>
                    <th>Quantity</th>
                    <th>Unit Cost</th>
                    <th>Expiry Date</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts?.map((product, index) => {
                    const handleQuantityChange = (event) => {
                      const updatedProducts = [...selectedProducts];
                      const value = parseInt(event.target.value);
                      updatedProducts[index] = {
                        ...product,
                        quantity: value,
                        packaging: Math.ceil(value / product.originalPackaging),
                        total: value * product.cost,
                      };
                      setSelectedProducts(updatedProducts);
                    };

                    const handlePackagingChange = (PackagingQte) => {
                      const updatedProducts = [...selectedProducts];
                      const value = parseInt(PackagingQte);
                      const newQuantity = value * product.originalPackaging;
                      updatedProducts[index] = {
                        ...product,
                        packaging: value,
                        quantity: value * product.originalPackaging,
                        total: newQuantity * product.cost,
                      };
                      setSelectedProducts(updatedProducts);
                    };

                    const handleCostChange = (event) => {
                      const updatedProducts = [...selectedProducts];
                      updatedProducts[index] = {
                        ...product,
                        cost:
                          parseInt(event.target.value) /
                          updatedProducts[index].quantity,
                        total: parseInt(event.target.value),
                      };
                      setSelectedProducts(updatedProducts);
                    };

                    const handleDateChange = (event) => {
                      const updatedProducts = [...selectedProducts];
                      updatedProducts[index] = {
                        ...product,
                        expiry_date: event.target.value,
                      };
                      setSelectedProducts(updatedProducts);
                    };

                    const handleRemoveClick = () => {
                      const updatedProducts = [...selectedProducts];
                      updatedProducts.splice(index, 1); // Remove the product at the current index
                      setSelectedProducts(updatedProducts);
                    };

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{product.name}</td>

                        <td>
                          <div className={purchaseStyles.sminputContainer}>
                            <FontAwesomeIcon
                              icon={faMinus}
                              color={"#009F7F"}
                              className={purchaseStyles.smicon}
                              onClick={() =>
                                handlePackagingChange((product.packaging -= 1))
                              }
                            />
                            <input
                              className={purchaseStyles.sminput}
                              type="number"
                              name="quantity"
                              value={product.packaging}
                              onChange={(e) =>
                                handlePackagingChange(e.target.value)
                              }
                            />
                            <FontAwesomeIcon
                              icon={faPlus}
                              color={"#009F7F"}
                              className={purchaseStyles.smicon}
                              onClick={() =>
                                handlePackagingChange((product.packaging += 1))
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <input
                            style={{ maxWidth: "50px", fontSize: "12px" }}
                            type="number"
                            name="quantity"
                            value={product.quantity}
                            onChange={handleQuantityChange}
                          />
                        </td>
                        <td>{parseFloat(product.cost).toFixed(0)}</td>
                        <td>
                          <input
                            style={{ maxWidth: "120px", fontSize: "12px" }}
                            type="date"
                            name="expiry_date"
                            value={product.expiry_date}
                            onChange={handleDateChange}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </td>
                        <td>
                          <input
                            style={{ maxWidth: "70px", fontSize: "12px" }}
                            type="number"
                            name="cost"
                            value={product.total}
                            onChange={handleCostChange}
                          />
                        </td>
                        <td>
                          <button onClick={handleRemoveClick}>Remove</button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={6} className={"text-end"}>
                      <b>Total</b>
                    </td>
                    <td>{subtotal}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"mx-2"}></div>
            <PrimaryButton
              name={loading ? "Ajout en cours..." : "Create Purchase Order"}
              onClick={loading ? null : submit}
              style={{
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
