import React, {useContext, useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import myContext from "../../../MyContext";
import { useNavigate } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeleteModal from "../../components/delete_modal/delete_modal";

export default function WarehousesList() {
    const [rows, setRows] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const deleteObj = (id) => {
        axiosInstance.delete('warehouse/' + id + '/').then((response) => {
            window.location.reload();
        })
    }
    const {setEditing} = useContext(myContext);
    const navigate = useNavigate()
    const editObj = (data) => {
        setEditing(data);
        navigate('/admin/warehouses/create');
    }

    const cols = [
        { title: "ID", internal: "id" },
        { title: "Name", internal: "name" },
        { title: "Slug", internal: "slug" },
        {
            title: "Actions",
            internal: 'id',
            code: (data, row) => {
                return (
                    <div className={'d-flex justify-content-end'}>
                        <div role={'button'} onClick={() => editObj(data)} className={'pe-3'}>
                            <FontAwesomeIcon icon={faPenToSquare} color={'#9EA3AC'}/>
                        </div>

                        <div role={'button'} onClick={() => {
                            setShowDelete(true);
                            setSelectedId(row);
                        }}>
                            <FontAwesomeIcon icon={faTrash} color={'#EF4444'}/>
                        </div>
                    </div>
                );
            }
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <DeleteModal show={showDelete} setter={setShowDelete} onClick={() => deleteObj(selectedId)}/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'warehouse'}
                    pull={setRows}
                    noPagination={true}
                    title={'warehouses'}
                    cols={cols}
                    rows={rows}
                    withIndex={true}
                />
            </div>
        </div>
    );
}