import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { formatTimestamp } from "../../components/utils";

export default function PurchaseOrderProducts() {
  const [rows, setRows] = useState([]);
  const cols = [
    { title: "Orders", internal: "orders", filter: true },
    {
      title: "Product",
      internal: "product_name",
      filter: true,
      filterInternal: "product_name",
    },
    { title: "Cost", internal: "product_cost", filter: true },
    { title: "F(x)", internal: "fx" },
    { title: "Stock", internal: "stock", filter: true },
    { title: "Supplier", internal: "supplier", filter: true },
    { title: "Warehouse", internal: "warehouse", filter: true },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          pull={setRows}
          fetchUrl={"purchase_order_product_auto"}
          title={"List of products"}
          cols={cols}
          rows={rows}
          noCreate={true}
        />
      </div>
    </div>
  );
}
