import React, {useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import {formatTimestamp} from "../../components/utils";

export default function ContactMessagesList() {
    const [rows, setRows] = useState([]);

    const cols = [
        {title: "Message", internal: "message" },
        {title: "User", internal: "user", code: (row, data) => {
            return data?.user_data.name + " " + data?.user_data.lastname;
        }},
        {title: "Phone Number", internal: "user", code: (row, data) => {
                return data?.user_data.phone_number;
            }},
        {title: "Email", internal: "user", code: (row, data) => {
                return data?.user_data.email;
            }},
        {title: "Sent at", internal: "created_at", code: (row, data) => {
            return formatTimestamp(data);
            } },
        ];

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4 pb-4`}>
                <TableView
                    fetchUrl={'contact_message'}
                    pull={setRows}
                    noPagination={true}
                    title={'Contact messages'}
                    cols={cols}
                    rows={rows}
                    withIndex={true}
                />
            </div>
        </div>
    )
}