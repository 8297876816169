import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  BigTextInput,
  FileInput,
  PrimaryButton,
  RadioInput,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation } from "react-router-dom";

export default function CategoriesCreate() {
  const [form, setForm] = useState({});
  const [sections, setSections] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      if (state.section) {
        state.section = state?.section.id;
      }

      if (state.supplier) {
        state.biller = state.supplier.id;
      }
      state.display = state.display ? 1 : 0;

      setForm(state);
    }

    axiosInstance.get("section/").then((response) => {
      setSections(response.data);
    });

    axiosInstance.get("supplier/").then((response) => {
      setSuppliers(response.data);
    });
  }, []);

  const back = () => {
    window.location.replace("/admin/categories");
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    if (state) {
      axiosInstance
        .put("category/" + state.id + "/", formData)
        .then((response) => {
          back();
        });
    } else {
      axiosInstance.post("category/", formData).then((response) => {
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Category</div>
          <div>
            Add your category details and necessary information from here
          </div>
        </div>
        <div className={styles.crudInputs}>
          <FileInput name={"image"} setForm={setForm} form={form} />
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"name"} setForm={setForm} form={form} />
            <TextInput name={"slug"} setForm={setForm} form={form} />
            <BigTextInput name={"description"} setForm={setForm} form={form} />
            <SelectInput
              name={"biller"}
              options={suppliers}
              setForm={setForm}
              form={form}
            />
            <SelectInput
              name={"section"}
              options={sections}
              setForm={setForm}
              form={form}
            />
            <RadioInput
              name={"display"}
              options={["No", "Yes"]}
              setForm={setForm}
              form={form}
            />
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update" : "Add Category"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
