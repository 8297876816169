import React, {useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import {
    BigTextInput,
    PrimaryButton, RadioInput,
    SecondaryButton,
    SelectInput,
    TextInput
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import {useLocation} from "react-router-dom";

export default function SubcategoriesCreate() {
    const [form, setForm] = useState({});
    const [categories, setCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const {state} = useLocation();

    useEffect(() => {
        if(state) {
            if(state['supplier'])
            {
                state['biller'] = state['supplier'].id;
            }

            if(state['category'])
            {
                state['category'] = state['category'].id;
            }

            state.display = state.display ? 1 : 0;

            setForm(state);
        }

        axiosInstance.get('category/').then((response) => {
            setCategories(response.data);
        });

        axiosInstance.get('supplier/').then((response) => {
            setSuppliers(response.data);
        });
    }, []);

    useEffect(() => {
        console.log(form);
    }, [form]);

    const back = () => {
        window.location.replace('/admin/subcategories');
    }

    const submit = () => {
        let formData = new FormData();
        for(const key in form) {
            formData.append(key, form[key]);
        }

        if(state) {
            axiosInstance.put('subcategory/' + state.id + '/', formData).then((response) => {
                back();
            });
        }
        else {
            axiosInstance.post('subcategory/', formData).then((response) => {
                back();
            });
        }
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>Create Subcategory</div>
                    <div>Add your subcategory details and necessary information from here</div>
                </div>
                <div className={styles.crudInputs}>
                    <div className={`${styles.createContainer} p-4`}>
                        <TextInput name={'name'} setForm={setForm} form={form}/>
                        <BigTextInput name={'description'} setForm={setForm} form={form}/>
                        <TextInput name={'slug'} setForm={setForm} form={form}/>
                        <SelectInput name={'category'} options={categories} setForm={setForm} form={form}/>
                        <SelectInput name={'biller'} options={suppliers} setForm={setForm} form={form}/>
                        <RadioInput name={'display'} options={['No', 'Yes']} setForm={setForm} form={form}/>
                    </div>

                    <div className={styles.buttonContainer}>
                        <SecondaryButton name={'Back'} onClick={back}/>
                        <div className={'ps-3'}/>
                        <PrimaryButton name={state ? 'Update' : 'Add Subcategory'} onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    );
}