import React, {useContext, useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import {
    PrimaryButton,
    SecondaryButton, SelectInput,
    TextInput
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

export default function AddProductToSubcategory() {
    const [form, setForm] = useState({});
    const {editing} = useContext(MyContext);
    const [timer, setTimer] = useState(null);
    const [foundProduct, setFoundProduct] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [biller, setBiller] = useState(null);

    useEffect(() => {
        setForm(editing);
        if(editing) {
            if(editing.supplier) setBiller(editing.supplier.id);
        }
    }, []);

    useEffect(() => {
        if (timer) {
            clearTimeout(timer);
        }

        if (form["product_name"]?.trim() !== '') {
            const newTimer = setTimeout(() => {
                let appendThing = '';
                if (biller) {
                    appendThing = `&biller_id=${biller}`;
                }
                axiosInstance.get(`product/subcategory_assign_search/?name=${form['product_name']}${appendThing}`).then((response) => {
                    setFoundProduct(response.data);
                });
            }, 400);

            setTimer(newTimer);
        }
    }, [form["product_name"]]);

    const back = () => {
        window.location.replace('/admin/subcategories');
    }

    const submit = () => {
        axiosInstance.post('subcategory/add_products/', {
            subcategory: editing.id,
            products: selectedProducts.map((product) => product.id)
        }).then((response) => {
            back();
        });
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>Subcategory Edit</div>
                    <div></div>
                </div>
                <div className={styles.crudInputs}>
                    <div className={`${styles.createContainer} p-4`}>
                        <div className={''}>
                            <div>
                                <div style={{fontWeight: "500"}}>Subcategory:</div>
                                <div className={'pb-3'}>{editing.name}</div>
                            </div>

                            <div>
                                <div style={{fontWeight: "500"}}>Products:</div>
                                {selectedProducts.map((product, index) => (
                                    <div key={index}>{product.name}</div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.createContainer} p-4`}>
                        <TextInput form={form} setForm={setForm} name={'Product'} internalName={'product_name'}/>
                        <div>
                        {foundProduct?.map((product, index) => (
                            <div key={index} className={'d-flex mb-2 px-2 py-2 align-items-center justify-content-between'} style={{borderRadius: '6px', border: '1px #ccc solid', cursor: 'pointer'}} onClick={() => {
                                setSelectedProducts([...selectedProducts, {id: product.id, name: product.name}]);
                                setFoundProduct([]);
                                setForm({...form, product_name: ''});
                            }}>
                                <div>{product.name}</div>
                                <FontAwesomeIcon icon={faPlus} color={'#009F7F'}/>
                            </div>
                        ))}
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <SecondaryButton name={'Back'} onClick={back}/>
                        <div className={'ps-3'}/>
                        <PrimaryButton name={'Add to Subcategory'} onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    );
}