import React, {useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";

export default function EmailTemplates() {
    const [rows, setRows] = useState([]);

    const typeToName = (data) => {
        if(data === 0) return "Inscription";
        if(data === 1) return "Confirmation of Order";
        if(data === 2) return "Billers mail";
        if(data === 3) return "Billers recap";
        if(data === 4) return "Automatic order";
        return "-";
    }

    const cols = [
        {title: 'Subject', internal: 'subject'},
        {title: 'Type', internal: 'type', code: (row, data) => {
            return typeToName(data);
        }},
        {title: '', internal: ''}
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'email_template'}
                    pull={setRows}
                    noPagination={true}
                    title={'Email Templates'}
                    cols={cols}
                    rows={rows}
                    withIndex={true}
                />
            </div>
        </div>
    )
}