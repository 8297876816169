import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import inputstyles from "../../components/styles/inputs.module.css";
import {
  BigTextInput,
  FileInput,
  PrimaryButton,
  RadioInput,
  SecondaryButton,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation } from "react-router-dom";
import { Select as Ajonjoselect } from "../../../ajonjolib/inputs/ajonjolinput";

export default function ProductsCreate() {
  const [form, setForm] = useState({
    packaging: 1,
    unlimited: 0,
    alert_quantity: 1,
  });
  const [suppliers, setSuppliers] = useState([]);
  const { state } = useLocation();
  const [startName, setStartName] = useState(null);

  const [variants, setVariants] = useState([]);
  const [removedVariants, setRemovedVariants] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);

  useEffect(() => {
    axiosInstance.get("biller/").then((response) => {
      setSuppliers(response.data);
    });
  }, []);

  useEffect(() => {
    if (state) {
      if (suppliers.length === 0) return;

      axiosInstance.get(`product/${state}/`).then((response) => {
        let data = response.data;
        if (data.biller) data.selected_supplier = data.biller;
        data.unlimited = data.unlimited ? 1 : 0;
        data.display = data.display ? 1 : 0;
        setForm(data);
        setStartName(data?.name);

        axiosInstance.get(`variants/?product_id=${state}`).then((response) => {
          setVariants(response.data);
        });
      });
    }
  }, [suppliers]);

  const back = () => {
    window.location.replace("/admin/products");
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    formData.append("variants", JSON.stringify(variants));
    formData.append("removed_variants", JSON.stringify(removedVariants));
    formData.append("removed_items", JSON.stringify(removedItems));
    formData.append("is_warehouse_product", true);

    if (state) {
      axiosInstance.put("product/" + state + "/", formData).then((response) => {
        back();
      });
    } else {
      axiosInstance.post("product/", formData).then((response) => {
        back();
      });
    }
  };

  useEffect(() => {
    let new_name = form["name"];
    if (new_name !== startName) {
      new_name = new_name?.toLowerCase()?.replaceAll(" ", "-");
      setForm({
        ...form,
        slug: new_name,
      });
    }
  }, [form["name"]]);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Product</div>
          <div>
            Add your product description and necessary information from here
          </div>
        </div>
        <div className={styles.crudInputs}>
          <FileInput name={"image"} setForm={setForm} form={form} />
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"name"} setForm={setForm} form={form} />
            <BigTextInput name={"description"} setForm={setForm} form={form} />
            <TextInput name={"cost"} setForm={setForm} form={form} />
            <TextInput name={"price"} setForm={setForm} form={form} />
            <TextInput
              name={"alert quantity"}
              internalName={"alert_quantity"}
              setForm={setForm}
              form={form}
            />
            <TextInput name={"slug"} setForm={setForm} form={form} />
            <TextInput name={"packaging"} setForm={setForm} form={form} />
            <div
              style={{ textAlign: "left", color: "#4B5563", fontWeight: "500" }}
            >
              Supplier
            </div>
            <Ajonjoselect
              searchable={true}
              value={form["selected_supplier"]}
              options={suppliers?.map((sup) => {
                return { value: sup.id, name: sup.name };
              })}
              onChange={(val) => {
                setForm({ ...form, selected_supplier: val[0] });
              }}
            />
            <RadioInput
              name={"unlimited"}
              options={["No", "Yes"]}
              setForm={setForm}
              form={form}
            />
            <RadioInput
              name={"display"}
              options={["No", "Yes"]}
              setForm={setForm}
              form={form}
            />

            <div>
              {variants.map((variant, index) => (
                <div key={index}>
                  <div className="form-group mb-3">
                    <div className={`${inputstyles.inputLabel} mb-1`}>
                      Variant Name
                    </div>
                    <input
                      type={"text"}
                      className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                      id={`variant_name_${index}`}
                      name="name"
                      value={variant.name}
                      onChange={(event) => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index] = {
                            ...prevVariants[index],
                            name: event.target.value,
                          };
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className={`${inputstyles.inputLabel} mb-1`}>
                      Variant Min
                    </div>
                    <input
                      type={"text"}
                      className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                      id={`variant_min_quantity_${index}`}
                      name="min_quantity"
                      value={variant.min_quantity}
                      onChange={(event) => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index] = {
                            ...prevVariants[index],
                            min_quantity: event.target.value,
                          };
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className={`${inputstyles.inputLabel} mb-1`}>
                      Variant Max
                    </div>
                    <input
                      type={"text"}
                      className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                      id={`variant_max_quantity_${index}`}
                      name="max_quantity"
                      value={variant.max_quantity}
                      onChange={(event) => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index] = {
                            ...prevVariants[index],
                            max_quantity: event.target.value,
                          };
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>

                  <div>
                    {variant.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className={"d-flex justify-content-between"}
                      >
                        <div className="form-group mb-3">
                          <div className={`${inputstyles.inputLabel} mb-1`}>
                            Item Name
                          </div>
                          <input
                            type={"text"}
                            className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                            id={`variant_item_name_${index}_${itemIndex}`}
                            name="name"
                            value={item.name}
                            onChange={(event) => {
                              setVariants((prevVariants) => {
                                const updatedVariants = [...prevVariants];
                                updatedVariants[index].items[itemIndex] = {
                                  ...prevVariants[index].items[itemIndex],
                                  name: event.target.value,
                                };
                                return updatedVariants;
                              });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <div className={`${inputstyles.inputLabel} mb-1`}>
                            Item Price
                          </div>
                          <input
                            type={"text"}
                            className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                            id={`variant_item_price_${index}_${itemIndex}`}
                            name="price"
                            value={item.price}
                            onChange={(event) => {
                              setVariants((prevVariants) => {
                                const updatedVariants = [...prevVariants];
                                updatedVariants[index].items[itemIndex] = {
                                  ...prevVariants[index].items[itemIndex],
                                  price: event.target.value,
                                };
                                return updatedVariants;
                              });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={"d-flex justify-content-end"}>
                    <SecondaryButton
                      name={"Add Item"}
                      onClick={() => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index].items.push({
                            name: "",
                            price: 0,
                          });
                          return updatedVariants;
                        });
                      }}
                    />
                    <SecondaryButton
                      name={"Remove Item"}
                      onClick={() => {
                        const currentVariant = variants[index];
                        const itemId =
                          currentVariant.items[currentVariant.items.length - 1]
                            .id;
                        setRemovedItems((prev) => [...prev, itemId]);
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index].items.pop();
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={"d-flex justify-content-end"}>
              <SecondaryButton
                name={"Add Variant"}
                onClick={() => {
                  setVariants((prevVariants) => [
                    ...prevVariants,
                    { name: "", min_quantity: 1, max_quantity: 1, items: [] },
                  ]);
                }}
              />
              <div className={"pe-2"} />
              <SecondaryButton
                name={"Remove Variant"}
                onClick={() => {
                  const id = variants[variants.length - 1].id;
                  setRemovedVariants((prev) => [...prev, id]);
                  setVariants((prevVariants) => {
                    const updatedVariants = [...prevVariants];
                    updatedVariants.pop();
                    return updatedVariants;
                  });
                }}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"pe-2"} />
            <PrimaryButton
              name={state ? "Update" : "Add Product"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
