import React, {useContext, useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faPlus, faRecycle, faTrash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";

export default function DeliveryGuysList() {
    const [rows, setRows] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const navigate = useNavigate();

    const deleteObj = (id) => {
        axiosInstance.delete('delivery_guy/' + id + '/').then((response) => {
            window.location.reload();
        })
    }

    const editObj = (data) => {
        navigate('/admin/deliveryguys/create', {state: data});
    }

    const changeStatus = (id) => {
        axiosInstance.put('delivery_guy/' + id + '/change_status/').then((response) => {
            window.location.reload();
        });
    }

    const cols = [
        {title: "Name", internal: "name"},
        {title: "Salary", internal: "salary"},
        {title: "Warehouse", internal: "warehouse.name"},
        {title: "Status", internal: "status", code: (data, row) => {
            if (row === 1) {
                return (<div>Active</div>)
            } else {
                return (<div>Inactive</div>)
            }
        }},
        {
            title: "Actions",
            internal: 'id',
            code: (data, row) => {
                return (
                    <div className={'d-flex justify-content-end'}>
                        <div role={'button'} onClick={() => changeStatus(row)} className={'pe-3'}>
                            <FontAwesomeIcon icon={faRecycle} color={'#9EA3AC'}/>
                        </div>

                        <div role={'button'} onClick={() => editObj(data)} className={'pe-3'}>
                            <FontAwesomeIcon icon={faPenToSquare} color={'#9EA3AC'}/>
                        </div>

                        <div role={'button'} onClick={() => {
                            setShowDelete(true);
                            setSelectedId(row);
                        }}>
                            <FontAwesomeIcon icon={faTrash} color={'#EF4444'}/>
                        </div>
                    </div>
                );
            }
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <DeleteModal show={showDelete} setter={setShowDelete} onClick={() => deleteObj(selectedId)}/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'delivery_guy'}
                    pull={setRows}
                    noPagination={true}
                    title={'delivery guys'}
                    cols={cols}
                    rows={rows}
                    withIndex={true}
                />
            </div>
        </div>
    );
}
