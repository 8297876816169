import React, {useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import {useNavigate} from "react-router-dom";

export default function BlogList() {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get('blog/').then((response) => {
            setRows(response.data);
        })
    }, []);

    const editObj = (id) => {
        const found = rows.filter((row) => { return row.id === id });
        navigate('/admin/blog/create', {state: found[0]});
    }

    const deleteObj = (id) => {
        axiosInstance.delete('blog/' + id + '/').then((response) => {
            window.location.reload();
        })
    }

    const cols = [
        { title: "Image", image: { url: "image.image" } },
        { title: "Title", internal: "title"},
        { title: "Text", internal: "text" },
        { title: "Actions",
            buttons: [
                {
                    icon: faTrash,
                    color: "#EF4444",
                    onClick: deleteObj
                },
                {
                    icon: faPenToSquare,
                    color: "#9EA3AC",
                    onClick: editObj
                }
            ]
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'blog'}
                    pull={setRows}
                    noPagination={true}
                    title={'blog'}
                    cols={cols}
                    rows={rows}
                />
            </div>
        </div>
    );
}
