import React, { useContext, useEffect, useState } from "react";
import styles from "./styles/adminsidebar.module.css";
import {
  faBagShopping,
  faBox,
  faBoxesStacked,
  faCalculator,
  faCartShopping,
  faChevronDown,
  faChevronUp,
  faCode,
  faCog,
  faCubesStacked,
  faDashboard,
  faMailBulk,
  faMotorcycle,
  faPencil,
  faPuzzlePiece,
  faStar,
  faStore,
  faTruckField,
  faUsers,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import MyContext from "../../MyContext";
import useGetUser from "../../hooks/useGetUser";

export default function AdminSidebar() {
  const navigate = useNavigate();

  const user = useGetUser();

  const permissions = parseInt(localStorage.getItem("permissions"));

  const goto = (url) => {
    setSidebarOpen(false);
    navigate(url);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (user?.data?.user_data?.role === 1) {
      setOptions([
        {
          title: "Dashboard",
          icon: faDashboard,
          goto: "/admin/dashboard",
        },
        {
          title: "Closures",
          icon: faCalculator,
          items: [
            {
              title: "Day Closure",
              goto: "/admin/closure/day",
              icon: faCalculator,
            },
            {
              title: "Closure List",
              goto: "/admin/closure/list",
              icon: faCalculator,
            },
            {
              title: "Closure Mensuel",
              goto: "/admin/closure/mensuel",
              icon: faCalculator,
            },
            {
              title: "Expense List",
              goto: "/admin/expense/list",
              icon: faCalculator,
            },
            { title: "Fee List", goto: "/admin/fee/list", icon: faCalculator },
          ],
          isOpen: false,
        },
        {
          title: "Products",
          icon: faBox,
          isOpen: false,
          items: [
            { title: "Product List", goto: "/admin/products", icon: faBox },
            { title: "Combos", goto: "/admin/combos", icon: faCubesStacked },
            {
              title: "Biller Products",
              goto: "/admin/billerproducts",
              icon: faBox,
            },
            { title: "Promotions", goto: "/admin/promotions", icon: faStar },
            { title: "Stock", icon: faBoxesStacked, goto: "/admin/stock" },
          ],
        },
        {
          title: "Sections",
          icon: faPuzzlePiece,
          items: [
            {
              title: "Section List",
              goto: "/admin/sections",
              icon: faPuzzlePiece,
            },
            { title: "Categories", icon: faStar, goto: "/admin/categories" },
            {
              title: "Subcategories",
              icon: faStar,
              goto: "/admin/subcategories",
            },
          ],
        },
        {
          title: "Warehouses",
          icon: faWarehouse,
          goto: "/admin/warehouses",
        },
        {
          title: "Sales",
          icon: faCartShopping,
          items: [
            // {
            //   title: "Payments List",
            //   goto: "/admin/payments",
            //   icon: faCartShopping,
            // },
            { title: "Sale List", goto: "/admin/sales", icon: faCartShopping },
            {
              title: "Owner Sale List",
              goto: "/admin/sales/owner",
              icon: faCartShopping,
            },
            {
              title: "Sale Details",
              icon: faCartShopping,
              goto: "/admin/sales/detail",
            },
            {
              title: "Sale Products",
              icon: faCartShopping,
              goto: "/admin/sales/products",
            },
            {
              title: "Sale Cancelled",
              goto: "/admin/sales/cancelled",
              icon: faCartShopping,
            },
            {
              title: "Sale API",
              goto: "/admin/sales/api",
              icon: faCartShopping,
            },
          ],
        },
        {
          title: "Purchases",
          icon: faBagShopping,
          items: [
            {
              title: "Purchase List",
              goto: "/admin/purchase_order",
              icon: faBagShopping,
            },
            {
              title: "Purchase List Detailed",
              goto: "/admin/purchase_order/detailed",
              icon: faBagShopping,
            },
            {
              title: "Manual Purchase",
              icon: faBagShopping,
              goto: "/admin/purchase_order/manual",
            },
            {
              title: "Automatic Purchase",
              icon: faBagShopping,
              goto: "/admin/purchase_order/automatic",
            },
            {
              title: "Purchase Products",
              icon: faBagShopping,
              goto: "/admin/purchase_order/products",
            },
          ],
        },
        {
          title: "Users",
          icon: faUsers,
          items: [
            {
              title: "Admin Accounts",
              icon: faUsers,
              goto: "/admin/adminaccounts",
            },
            { title: "User List", goto: "/admin/users", icon: faUsers },
            {
              title: "Suppliers",
              icon: faTruckField,
              goto: "/admin/suppliers",
            },
            { title: "Billers", icon: faStore, goto: "/admin/billers" },
            {
              title: "Delivery Guys",
              icon: faMotorcycle,
              goto: "/admin/deliveryguys",
            },
          ],
        },
        {
          title: "Phone Tokens",
          icon: faCode,
          goto: "/admin/tokens",
        },
        {
          title: "Blog",
          icon: faPencil,
          goto: "/admin/blog",
        },
        {
          title: "Settings",
          icon: faCog,
          goto: "/admin/settings",
        },
        {
          title: "Contact Messages",
          icon: faPencil,
          goto: "/admin/contact_messages",
        },
        {
          title: "Email Templates",
          icon: faMailBulk,
          goto: "/admin/email_templates",
        },
      ]);
    } else if (user?.data?.user_data?.role === 3) {
      setOptions([
        {
          title: "Products",
          icon: faBox,
          goto: "/admin/billerproducts",
        },
        {
          title: "Subcategories",
          icon: faPuzzlePiece,
          goto: "/admin/subcategories",
        },
        {
          title: "Sales",
          icon: faCartShopping,
          goto: "/admin/sales",
        },
      ]);
    } else if (user?.data?.user_data?.role === 4) {
      setOptions([
        {
          title: "Products",
          icon: faBox,
          isOpen: false,
          items: [
            { title: "Product List", goto: "/admin/products", icon: faBox },
            { title: "Combos", goto: "/admin/combos", icon: faCubesStacked },
            {
              title: "Biller Products",
              goto: "/admin/billerproducts",
              icon: faBox,
            },
            { title: "Promotions", goto: "/admin/promotions", icon: faStar },
            { title: "Stock", icon: faBoxesStacked, goto: "/admin/stock" },
          ],
        },
        {
          title: "Day Closure",
          icon: faCalculator,
          goto: "/admin/closure",
        },
        {
          title: "Sales",
          icon: faCartShopping,
          items: [
            { title: "Sale List", goto: "/admin/sales", icon: faCartShopping },

            {
              title: "Sale Details",
              icon: faCartShopping,
              goto: "/admin/sales/detail",
            },
          ],
        },
        {
          title: "Purchases",
          icon: faBagShopping,
          items: [
            {
              title: "Purchase List",
              goto: "/admin/purchase_order",
              icon: faBagShopping,
            },
            {
              title: "Purchase List Detailed",
              goto: "/admin/purchase_order/detailed",
              icon: faBagShopping,
            },
            {
              title: "Manual Purchase",
              icon: faBagShopping,
              goto: "/admin/purchase_order/manual",
            },
            {
              title: "Automatic Purchase",
              icon: faBagShopping,
              goto: "/admin/purchase_order/automatic",
            },
            {
              title: "Purchase Products",
              icon: faBagShopping,
              goto: "/admin/purchase_order/products",
            },
          ],
        },
      ]);
    } else if (user?.data?.user_data?.role !== 0) {
      setOptions([
        {
          title: "Products",
          icon: faBox,
          isOpen: false,
          items: [
            { title: "Product List", goto: "/admin/products", icon: faBox },
            { title: "Stock", icon: faBoxesStacked, goto: "/admin/stock" },
          ],
        },
        {
          title: "Day Closure",
          icon: faCalculator,
          goto: "/admin/closure",
        },
        {
          title: "Sales",
          icon: faCartShopping,
          items: [
            { title: "Sale List", goto: "/admin/sales", icon: faCartShopping },
            {
              title: "Sale Details",
              icon: faCartShopping,
              goto: "/admin/sales/detail",
            },
          ],
        },
        {
          title: "Purchases",
          icon: faBagShopping,
          items: [
            {
              title: "Purchase List",
              goto: "/admin/purchase_order",
              icon: faBagShopping,
            },
            {
              title: "Automatic Purchase",
              icon: faBagShopping,
              goto: "/admin/purchase_order/automatic",
            },
          ],
        },
      ]);
    }
  }, [user?.data]);

  const { sidebarOpen, setSidebarOpen } = useContext(MyContext);

  useEffect(() => {
    if (sidebarOpen) {
      document.documentElement.style.setProperty("--global-margin", "300px");
    } else {
      document.documentElement.style.setProperty("--global-margin", "20px");
    }
  }, [sidebarOpen]);

  return (
    <div
      className={`${styles.container} ${
        !sidebarOpen && styles.hide
      }  shadow-sm `}
    >
      {options.map((option, index) => (
        <div key={index}>
          {option.items ? (
            <div>
              <div
                className={`${styles.listItem} d-flex flex-row ps-3 align-items-center`}
                onClick={() => {
                  setOptions((prevOptions) => {
                    const copy = [...prevOptions]; // Create a copy of the options array
                    copy[index].isOpen = !copy[index].isOpen;
                    return copy; // Return the updated copy
                  });
                }}
              >
                <div className={"d-flex"} style={{ flexBasis: "70%" }}>
                  <FontAwesomeIcon icon={option.icon} className={"pt-1 pe-3"} />
                  <div>{option.title}</div>
                </div>
                <FontAwesomeIcon
                  icon={!option.isOpen ? faChevronDown : faChevronUp}
                  className={"ps-4"}
                />
              </div>
              {option.isOpen &&
                option.items?.map((item, index) => (
                  <div
                    className={`${styles.listItem} d-flex flex-row ps-5`}
                    onClick={() => goto(item.goto)}
                  >
                    <FontAwesomeIcon icon={item.icon} className={"pt-1 pe-3"} />
                    <div key={index}>{item.title}</div>
                  </div>
                ))}
            </div>
          ) : (
            <div
              className={`${styles.listItem} d-flex flex-row ps-3`}
              onClick={() => goto(option.goto)}
            >
              <FontAwesomeIcon icon={option.icon} className={"pt-1 pe-3"} />
              <div>{option.title}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
