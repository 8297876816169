import React, {useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {formatTimestamp} from "../../components/utils";

export default function NotificationsList() {
    const [rows, setRows] = useState([]);

    const cols = [
        { title: "Phone", internal: "phone_number" },
        { title: "Code", internal: "code" },
        { title: "Used", internal: "used", isBoolean: true },
        { title: "Timestamp", internal: "created_at", code: (row, data) => formatTimestamp(data) }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4 pb-3`}>
                <TableView
                    fetchUrl={'phone_verification'}
                    pull={setRows}
                    title={'Phone Tokens'}
                    cols={cols}
                    rows={rows}
                    noCreate={true}
                />
            </div>
        </div>
    );
}
