import React, {useContext, useEffect, useState} from "react";
import MyContext from "../../../MyContext";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import {
    BigTextInput, FileInput,
    PrimaryButton,
    RadioInput,
    SecondaryButton,
    TextInput
} from "../../components/inputs";

export default function SectionCreate() {
    const [form, setForm] = useState({});
    const {editing} = useContext(MyContext);

    useEffect(() => {
        editing.display = editing.display === true ? 1 : 0;
        setForm(editing);
    }, []);

    const back = () => {
        window.location.replace('/admin/sections');
    }

    const submit = () => {
        let formData = new FormData();
        for(const key in form) {
            formData.append(key, form[key]);
        }

        if(editing) {
            axiosInstance.put('section/' + editing.id + '/', formData).then((response) => {
                back();
            });
        }
        else {
            axiosInstance.post('section/', formData).then((response) => {
                back();
            });
        }
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>Create Section</div>
                    <div>Add your section</div>
                </div>
                <div className={styles.crudInputs}>
                    <FileInput name={'image'} setForm={setForm} form={form}/>
                    <div className={`${styles.createContainer} p-4`}>
                        <TextInput name={'name'} setForm={setForm} form={form}/>
                        <BigTextInput name={'description'} setForm={setForm} form={form}/>
                        <RadioInput name={'display'} form={form} setForm={setForm} options={['No', 'Yes']}/>
                    </div>

                    <div className={styles.buttonContainer}>
                        <SecondaryButton name={'Back'} onClick={back}/>
                        <div className={'ps-3'}/>
                        <PrimaryButton name={editing ? 'Update' : 'Add Section'} onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    );
}