import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { formatTimestamp } from "../../components/utils";
import { formatPrice } from "../../../utils/utils";

export default function ClosureList() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const viewDetail = (data) => {
    navigate("/admin/clousure/detail", { state: data });
  };

  const cols = [
    {
      title: "Date",
      internal: "date",
      filter: true,
      dateFilter: true,
      code: (data, row) => formatTimestamp(row),
    },
    { title: "Warehouse", internal: "warehouse_name", filter: true },
    { title: "Sales", internal: "sales.count" },
    {
      title: "Initial Cash",
      internal: "money_start",
      filter: true,
      code: (data, row) => formatPrice(row),
    },
    {
      title: "Subtotal",
      internal: "sales.subtotal",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Profits",
      internal: "sales.profit",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Total Delivery",
      internal: "delivery_guy_expenses",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Total Expenses",
      internal: "delivery_closure_expenses",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Final Cash",
      internal: "money_end",
      filter: true,
      code: (row, data) => {
        return formatPrice(data);
      },
    },
    {
      title: "Actions",
      internal: "id",
      code: (row, data) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div role={"button"} onClick={() => viewDetail(data)}>
              <FontAwesomeIcon icon={faEye} color={"#9EA3AC"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          title={"closures"}
          fetchUrl={"day_closure/simple"}
          cols={cols}
          pull={setRows}
          rows={rows}
          withIndex={false}
          noCreate={true}
          limit={50}
          useReactQuery={"closure-list"}
        />
      </div>
    </div>
  );
}
