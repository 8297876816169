import React, {useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import {useNavigate} from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function CategoriesList() {
    const [rows, setRows] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const deleteObj = (id) => {
        axiosInstance.delete('category/' + id + '/').then((response) => {
            window.location.reload();
        })
    }

    const navigate = useNavigate()
    const editObj = (data) => {
        navigate('/admin/categories/create', {state: data});
    }

    const cols = [
        { title: "Image", image: { url: "image.image" } },
        { title: "Name", internal: "name", filter: true },
        { title: "Section", internal: "section.name" },
        { title: "Display", internal: "display", isBoolean: true },
        {
            title: "Actions",
            internal: 'id',
            code: (data, row) => {
                return (
                    <div className={'d-flex justify-content-end'}>
                        <div role={'button'} onClick={() => editObj(data)} className={'pe-3'}>
                            <FontAwesomeIcon icon={faPenToSquare} color={'#9EA3AC'}/>
                        </div>

                        <div role={'button'} onClick={() => {
                            setShowDelete(true);
                            setSelectedId(row);
                        }}>
                            <FontAwesomeIcon icon={faTrash} color={'#EF4444'}/>
                        </div>
                    </div>
                );
            }
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <DeleteModal show={showDelete} setter={setShowDelete} onClick={() => deleteObj(selectedId)}/>

            <div className={`${styles.container} me-4 pb-4`}>
                <TableView
                    fetchUrl={'category'}
                    noPagination={true}
                    pull={setRows}
                    title={'categories'}
                    cols={cols}
                    rows={rows}
                    withIndex={true}
                />
            </div>
        </div>
    );
}