import React, { useEffect, useState } from "react";
import ShopHeader from "../components/header/header";
import axiosInstance from "../../AxiosInstance";
import styles from "./categories.module.css";
import { useNavigate } from "react-router-dom";
import DeliveryText from "../components/delivery_text/delivery_text";
import PageTitle from "../components/page_title/page_title";
import { PrimaryButton, TextInput } from "../../admin/components/inputs";
import Cart from "../components/cart/cart";
import ProductCard from "../components/product_card/product_card";
import productstyles from "../products/products.module.css";
import ProductModal from "../components/product_modal/product_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Text from "../../components/ajonjolib/inputs/text/text";
import Loading from "../../admin/components/loading";

function CategorySquare({ category, categories }) {
  const navigate = useNavigate();

  return (
    <div
      className={styles.category}
      onClick={() =>
        navigate("/products/", {
          state: { category: category, categories: categories },
        })
      }
    >
      <div className={"pb-2"}>{category.name}</div>
      <img
        alt={"category_image"}
        src={category.image.image}
        style={{ objectFit: "contain", width: "100%", height: "auto" }}
      />
    </div>
  );
}

export default function Categories() {
  const [showPromos, setShowPromos] = useState(false);
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({});
  const [showSearch, setShowSearch] = useState(false);
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [cartItems, setCartItems] = useState([]);
  const [refreshCart, setRefreshCart] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const district = JSON.parse(localStorage.getItem("district"));
  const [showLogin, setShowLogin] = useState(0);
  const [locked, setLocked] = useState(false);
  const [loading, setLoading] = useState(false); // Ajout de l'état pour le loader

  const getPromoProducts = async () => {
    setLoading(true);
    axiosInstance
      .get(`product/promos/?unlogged_warehouse=${district.warehouse_id}`)
      .then((response) => {
        setShowPromos(response?.data?.results.length > 0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    document.title = "Categories | Bring Me";
    setLoading(true);
    axiosInstance
      .get("section/6/")
      .then((response) => {
        setCategories([
          {
            id: -1,
            name: "Promotions",
            image: { image: "/promo.png" },
          },
          ...response?.data,
        ]);
      })
      .finally(() => setLoading(false)); // Cacher le loader après la requête
    const response = getPromoProducts();
  }, []);

  useEffect(() => {
    if (form["query"] === undefined) {
      setShowSearch(false);
      return;
    }
    const query = form["query"];
    if (query !== "") {
      axiosInstance
        .get(
          `product/?max_query=10&all_warehouse=${true}&combos=${true}&shop=1&quantity=1&name=${query}`
        )
        .then((response) => {
          setProducts(response?.data.results);
          setShowSearch(true);
        });
    } else {
      setShowSearch(false);
    }
  }, [form["query"]]);

  const addToCart = () => {
    axiosInstance
      .post(`cart/add/${selectedProduct.id}/`, {
        quantity: quantity,
        district_id: district.id,
      })
      .then((response) => {
        if (response?.response?.status === 500) {
          setShowLogin((prev) => prev + 1);
        }
        setShowModal(false);
        setQuantity(1);
        setRefreshCart((prev) => prev + 1);
      });
  };

  const checkQuantity = (newQuantity) => {
    setLocked(true);
    if (newQuantity < 0 && quantity === 1) return;

    axiosInstance
      .get(`product/${selectedProduct.id}/count/${district.id}/`)
      .then((response) => {
        const foundQuantity = response?.data.quantity;
        if (foundQuantity >= quantity + newQuantity) {
          setQuantity((prevQuantity) => prevQuantity + newQuantity);
        }
        setLocked(false);
      });
  };

  return (
    <div>
      <ShopHeader showLogin={showLogin} />

      <Cart refreshCart={refreshCart} outCartItems={setCartItems} />

      <div>
        <ProductModal
          locked={locked}
          quantity={quantity}
          setShowModal={setShowModal}
          showModal={showModal}
          selectedProduct={selectedProduct}
          addToCart={addToCart}
          checkQuantity={checkQuantity}
        />
      </div>

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle
          name={"Epicerie en ligne"}
          description={"Livré en 20 minutes"}
        >
          <div
            className={"d-flex justify-content-center mt-2 align-items-center"}
          >
            <div className={`${styles.responsiveSearch} me-2`}>
              <Text
                style={{ width: "100%" }}
                value={form["query"]}
                onChange={(e) => setForm({ ...form, query: e })}
                placeholder={"Recherchez vos produits à partir d'ici"}
              />
            </div>
            <div style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faX}
                size={"lg"}
                color={"#00A6A8"}
                onClick={() => setForm({ ...form, query: "" })}
              />
            </div>
          </div>
        </PageTitle>

        <div className={`${styles.responsiveContainer} pb-5`}>
          <DeliveryText />
          {loading ? (
            <Loading />
          ) : showSearch ? (
            <div className={`${productstyles.responsiveFlexWarehouse}`}>
              {products.map((product, index2) => (
                <div
                  key={product.id}
                  className={`d-flex justify-content-center mb-2 ${productstyles.responsiveProductWarehouse}`}
                >
                  <ProductCard
                    setShowLogin={setShowLogin}
                    cartItems={cartItems}
                    product={product}
                    setRefreshCart={setRefreshCart}
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowModal(true);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={`d-flex flex-wrap justify-content-between pt-4`}>
              {categories.map((category, index) => {
                if (category.id === -1 && !showPromos) return null;
                return (
                  <div className={`${styles.categoryContainer}`}>
                    <CategorySquare
                      category={category}
                      categories={categories}
                      key={index}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {/* {showSearch ? (
            <div className={`${productstyles.responsiveFlexWarehouse}`}>
              {products.map((product, index2) => (
                <div
                  key={product.id}
                  className={`d-flex justify-content-center mb-2 ${productstyles.responsiveProductWarehouse}`}
                >
                  <ProductCard
                    setShowLogin={setShowLogin}
                    cartItems={cartItems}
                    product={product}
                    setRefreshCart={setRefreshCart}
                    onClick={() => {
                      setSelectedProduct(product);
                      setShowModal(true);
                    }}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={`d-flex flex-wrap justify-content-between pt-4`}>
              {categories.map((category, index) => {
                if (category.id === -1 && !showPromos) return null;
                return (
                  <div className={`${styles.categoryContainer}`}>
                    <CategorySquare
                      category={category}
                      categories={categories}
                      key={index}
                    />
                  </div>
                );
              })}
            </div>
          )
          } */}
        </div>
      </div>
    </div>
  );
}
