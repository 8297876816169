import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import { ContextProvider } from "./MyContext";
import ToastContainer from "./components/ajonjolib/toasts/toast/toast_container";
import axiosInstance from "./AxiosInstance";
import { useEffect } from "react";

function App() {
  const district = JSON.parse(localStorage.getItem("district"));
  const access_token = localStorage.getItem("access_token");
  const permissions = parseInt(localStorage.getItem("permissions"));

  useEffect(() => {
    axiosInstance.get("current_user/").then((response) => {
      if (response?.response?.status === 500) {
        localStorage.removeItem("access_token");
      }
      if (
        district?.id !== response?.data?.user_data?.district?.id &&
        access_token
      ) {
        if (!permissions) {
          if (window.location.pathname !== "/locate") {
            alert("Veuillez mettre à jour votre position");
            window.location.href = "/locate";
          }
        }
      }
    });
  }, []);
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <RouteContainer />
        </Router>
        <ToastContainer />
      </ContextProvider>
    </div>
  );
}

export default App;
