import React, {useContext, useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import {
    PrimaryButton, RadioInput,
    SecondaryButton,
    TextInput
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";

export default function SuppliersCreate() {
    const [form, setForm] = useState({});
    const {editing} = useContext(MyContext);

    useEffect(() => {
        if(Object.keys(editing).length > 0) {
            editing.automatic_order = editing.automatic_order ? 1 : 0;
            setForm(editing);
            console.log(editing);
        }
    }, []);

    const back = () => {
        window.location.replace('/admin/suppliers');
    }

    const submit = () => {
        let formData = new FormData();
        for(const key in form) {
            formData.append(key, form[key]);
        }

        if(Object.keys(editing).length > 0) {
            axiosInstance.put('biller/' + editing.id + '/', formData).then((response) => {
                back();
            });
        }
        else {
            axiosInstance.post('biller/', formData).then((response) => {
                back();
            });
        }
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>Create Supplier</div>
                    <div>Add your supplier details and necessary information from here</div>
                </div>
                <div className={styles.crudInputs}>
                    <div className={`${styles.createContainer} p-4`}>
                        <TextInput name={'name'} setForm={setForm} form={form}/>
                        <TextInput name={'Contact Name'} internalName={'contact_name'} setForm={setForm} form={form}/>
                        <TextInput name={'Contact Number'} internalName={'contact_number'} setForm={setForm} form={form}/>
                        <TextInput name={'Contact Email'} internalName={'contact_email'} setForm={setForm} form={form}/>
                        <TextInput name={'city'} setForm={setForm} form={form}/>
                        <TextInput name={'country'} setForm={setForm} form={form}/>
                        <RadioInput name={'Automatic Order'} internalName={'automatic_order'} options={['No', 'Yes']} setForm={setForm} form={form}/>
                    </div>

                    <div className={styles.buttonContainer}>
                        <SecondaryButton name={'Back'} onClick={back}/>
                        <div className={'ps-3'}/>
                        <PrimaryButton name={Object.keys(editing).length > 0 ? 'Update' : 'Add Supplier'} onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    );
}