import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import axiosInstance from "../../../AxiosInstance";
import { TextInput } from "../../components/inputs";
import TableHeader from "../../components/table_header";
import TableComponent from "../../components/table";
import Modal from "../../components/modal/modal";
import { useLocation } from "react-router-dom";
import { formatTimestamp, getOrderStatus } from "../../components/utils";
import { formatPrice } from "../../../utils/utils";

export default function ClosureDetail() {
  const { state } = useLocation();
  const [sales, setSales] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [deliveryGuys, setDeliveryGuys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState({});

  useEffect(() => {
    axiosInstance.get(`day_closure/${state}/`).then((response) => {
      setSales(response.data.sales);
      setDeliveryGuys(response.data.delivery_guy_expenses);
      setExpenses(response.data.delivery_closure_expenses);
    });
  }, []);

  const cols = [
    {
      title: "Date",
      internal: "date",
      code: (data, row) => formatTimestamp(row),
    },
    {
      title: "Client",
      internal: "user",
      code: (data, row) =>
        row?.user_data?.name + " " + row?.user_data?.lastname,
    },
    { title: "Orders", internal: "user_sales" },
    { title: "Phone", internal: "user.user_data.phone_number" },
    { title: "District", internal: "district.name" },
    { title: "Fee", internal: "fee" },
    {
      title: "Total",
      internal: "sub_total",
      code: (row, data) => {
        return row.sub_total + row.fee;
      },
    },
    {
      title: "Status",
      internal: "status",
      code: (data, row) => getOrderStatus(parseInt(row)),
    },
    { title: "Delivery Guy", internal: "delivery_guy.name" },
  ];

  const expenseCols = [
    { title: "Motive", internal: "name" },

    {
      title: "Category",
      internal: "category",

      code: (row) => row.category.toUpperCase(), // Convertit en majuscules
    },
    { title: "Amount", internal: "amount" },
  ];

  const deliveryCols = [
    { title: "Name", internal: "delivery_guy.name" },
    { title: "Amount", internal: "amount" },
  ];

  const createExpense = () => {
    axiosInstance.post("day_closure_expense/", form).then((response) => {
      setShowModal(false);
      window.location.reload();
    });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <Modal
        name={"Create Expense"}
        setter={setShowModal}
        show={showModal}
        buttonName={"Create"}
        onClick={createExpense}
      >
        <TextInput name={"name"} form={form} setForm={setForm} />
        <TextInput name={"amount"} form={form} setForm={setForm} />
      </Modal>

      <div className={`${styles.container} me-4`}>
        <TableHeader title={"Day Closing"} noCreate={true} withImport={false} />
        <div className={"my-3 text-start"}>
          <div className={"mb-1"}>
            <b>Warehouse: </b>
            {state.warehouse?.name}
          </div>
          <div className={"mb-1"}>
            <b>Initial Cash: </b>
            {formatPrice(state.money_start)}
          </div>
          <div className={"mb-1"}>
            <b>Final Cash: </b>
            {formatPrice(state.money_end)}
          </div>
        </div>
        <div style={{ textAlign: "left" }}>
          <b>Sales</b>
        </div>
        <div style={{ overflowX: "scroll" }}>
          <TableComponent cols={cols} rows={sales} />
        </div>

        <div className={"d-flex mt-4 flex-wrap"}>
          <div className={styles.halfContainer}>
            <div
              className={
                "d-flex align-items-center mb-2 justify-content-between"
              }
              style={{ height: "3.2rem" }}
            >
              <div style={{ textAlign: "left" }}>
                <b>List of Expenses</b>
              </div>
              <div>
                <b>Total: </b>
                {formatPrice(expenses.reduce((a, b) => a + b.amount, 0))}
              </div>
            </div>
            <TableComponent cols={expenseCols} rows={expenses} />
          </div>
          <div style={{ flexBasis: "4%" }} />
          <div className={styles.halfContainer}>
            <div
              className={
                "d-flex align-items-center mb-2 justify-content-between"
              }
              style={{ height: "3.2rem" }}
            >
              <div style={{ textAlign: "left" }}>
                <b>List of Delivery Guys</b>
              </div>
              <div>
                <b>Total: </b>
                {formatPrice(deliveryGuys.reduce((a, b) => a + b.amount, 0))}
              </div>
            </div>
            <TableComponent cols={deliveryCols} rows={deliveryGuys} />
          </div>
        </div>
      </div>
    </div>
  );
}
