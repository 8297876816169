import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  BigTextInput,
  FileInput,
  PrimaryButton,
  RadioInput,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import TimePicker from "react-time-picker";
import { useLocation } from "react-router-dom";

export default function BillersCreate() {
  const [form, setForm] = useState({});
  const [sections, setSections] = useState([]);
  const [districts, setDistricts] = useState([]);
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      if (state?.section) {
        state.section = state.section.id;
      }
      state.active = state.active ? 1 : 0;
      setForm(state);
    }

    axiosInstance.get("section/").then((response) => {
      setSections(response.data);
    });

    axiosInstance.get("district/").then((response) => {
      setDistricts(response.data);
    });
  }, []);

  const back = () => {
    window.location.replace("/admin/billers");
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      const value = form[key];
      if (value !== null) {
        formData.append(key, value);
      }
    }

    if (state) {
      axiosInstance
        .put("supplier/" + state.id + "/", formData)
        .then((response) => {
          back();
        });
    } else {
      axiosInstance.post("supplier/", formData).then((response) => {
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Biller</div>
          <div>Add your biller details and necessary information from here</div>
        </div>
        <div className={styles.crudInputs}>
          <FileInput form={form} setForm={setForm} internalName={"image"} />
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"name"} setForm={setForm} form={form} />
            <TextInput name={"slug"} setForm={setForm} form={form} />
            <BigTextInput name={"description"} setForm={setForm} form={form} />
            <SelectInput
              name={"section"}
              options={sections}
              setForm={setForm}
              form={form}
            />
            <div className={"mb-3"} />
            <TextInput
              name={"Commission"}
              internalName={"commission"}
              setForm={setForm}
              form={form}
            />
            <TextInput
              name={"Delivery Time"}
              internalName={"delivery_time"}
              setForm={setForm}
              form={form}
            />
            <TextInput
              name={"Free Delivery"}
              internalName={"free_delivery"}
              setForm={setForm}
              form={form}
            />{" "}
            {/*Min Delivery*/}
            <RadioInput
              name={"active"}
              form={form}
              setForm={setForm}
              options={["No", "Yes"]}
            />
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Monday</div>
                <TimePicker
                  value={form.open_1}
                  onChange={(val) => setForm({ ...form, open_1: val })}
                />
              </div>
              <div>
                <div>Pause Monday</div>
                <TimePicker
                  value={form.pause_1}
                  onChange={(val) => setForm({ ...form, pause_1: val })}
                />
              </div>
              <div>
                <div>Back Monday</div>
                <TimePicker
                  value={form.back_1}
                  onChange={(val) => setForm({ ...form, back_1: val })}
                />
              </div>
              <div>
                <div>Close Monday</div>
                <TimePicker
                  value={form.close_1}
                  onChange={(val) => setForm({ ...form, close_1: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Tuesday</div>
                <TimePicker
                  value={form.open_2}
                  onChange={(val) => setForm({ ...form, open_2: val })}
                />
              </div>
              <div>
                <div>Pause Tuesday</div>
                <TimePicker
                  value={form.pause_2}
                  onChange={(val) => setForm({ ...form, pause_2: val })}
                />
              </div>
              <div>
                <div>Back Tuesday</div>
                <TimePicker
                  value={form.back_2}
                  onChange={(val) => setForm({ ...form, back_2: val })}
                />
              </div>
              <div>
                <div>Close Tuesday</div>
                <TimePicker
                  value={form.close_2}
                  onChange={(val) => setForm({ ...form, close_2: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Wednesday</div>
                <TimePicker
                  value={form.open_3}
                  onChange={(val) => setForm({ ...form, open_3: val })}
                />
              </div>
              <div>
                <div>Pause Wednesday</div>
                <TimePicker
                  value={form.pause_3}
                  onChange={(val) => setForm({ ...form, pause_3: val })}
                />
              </div>
              <div>
                <div>Back Wednesday</div>
                <TimePicker
                  value={form.back_3}
                  onChange={(val) => setForm({ ...form, back_3: val })}
                />
              </div>
              <div>
                <div>Close Wednesday</div>
                <TimePicker
                  value={form.close_3}
                  onChange={(val) => setForm({ ...form, close_3: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Thursday</div>
                <TimePicker
                  value={form.open_4}
                  onChange={(val) => setForm({ ...form, open_4: val })}
                />
              </div>
              <div>
                <div>Pause Thursday</div>
                <TimePicker
                  value={form.pause_4}
                  onChange={(val) => setForm({ ...form, pause_4: val })}
                />
              </div>
              <div>
                <div>Back Thursday</div>
                <TimePicker
                  value={form.back_4}
                  onChange={(val) => setForm({ ...form, back_4: val })}
                />
              </div>
              <div>
                <div>Close Thursday</div>
                <TimePicker
                  value={form.close_4}
                  onChange={(val) => setForm({ ...form, close_4: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Friday</div>
                <TimePicker
                  value={form.open_5}
                  onChange={(val) => setForm({ ...form, open_5: val })}
                />
              </div>
              <div>
                <div>Pause Friday</div>
                <TimePicker
                  value={form.pause_5}
                  onChange={(val) => setForm({ ...form, pause_5: val })}
                />
              </div>
              <div>
                <div>Back Friday</div>
                <TimePicker
                  value={form.back_5}
                  onChange={(val) => setForm({ ...form, back_5: val })}
                />
              </div>
              <div>
                <div>Close Friday</div>
                <TimePicker
                  value={form.close_5}
                  onChange={(val) => setForm({ ...form, close_5: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Saturday</div>
                <TimePicker
                  value={form.open_6}
                  onChange={(val) => setForm({ ...form, open_6: val })}
                />
              </div>
              <div>
                <div>Pause Saturday</div>
                <TimePicker
                  value={form.pause_6}
                  onChange={(val) => setForm({ ...form, pause_6: val })}
                />
              </div>
              <div>
                <div>Back Saturday</div>
                <TimePicker
                  value={form.back_6}
                  onChange={(val) => setForm({ ...form, back_6: val })}
                />
              </div>
              <div>
                <div>Close Saturday</div>
                <TimePicker
                  value={form.close_6}
                  onChange={(val) => setForm({ ...form, close_6: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-between pb-2"}>
              <div>
                <div>Open Sunday</div>
                <TimePicker
                  value={form.open_7}
                  onChange={(val) => setForm({ ...form, open_7: val })}
                />
              </div>
              <div>
                <div>Pause Sunday</div>
                <TimePicker
                  value={form.pause_7}
                  onChange={(val) => setForm({ ...form, pause_7: val })}
                />
              </div>
              <div>
                <div>Back Sunday</div>
                <TimePicker
                  value={form.back_7}
                  onChange={(val) => setForm({ ...form, back_7: val })}
                />
              </div>
              <div>
                <div>Close Sunday</div>
                <TimePicker
                  value={form.close_7}
                  onChange={(val) => setForm({ ...form, close_7: val })}
                />
              </div>
            </div>
            <div className={"pt-3"}>
              {districts.map((district, index) => {
                return (
                  <div
                    className={
                      "d-flex justify-content-between align-items-center"
                    }
                    style={{ borderTop: "1px solid #D6D6D6" }}
                  >
                    <div>{district.name}</div>
                    <div className={"py-3"}>
                      <TextInput
                        name={`fee_${district.id}`}
                        form={form}
                        setForm={setForm}
                        hideTitle={true}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update" : "Add Biller"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
