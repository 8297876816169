import React, {useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DeleteModal from "../../components/delete_modal/delete_modal";
import {formatTimestamp} from "../../components/utils";

export default function UsersList() {
    const [rows, setRows] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        axiosInstance.get('user_data_secure/').then((response) => {
            setRows(response.data);
        })
    }, []);

    const deleteObj = (id) => {
        axiosInstance.delete('admin_accounts/' + id + '/').then((response) => {
            window.location.reload();
        })
    }


    const cols = [
        { title: "Name", internal: "name", filter: true, filterInternal: 'name' },
        { title: "Last Name", internal: "lastname", filter: true, filterInternal: 'lastname' },
        { title: "Phone Number", internal: "phone_number", filter: true, filterInternal: 'phone_number'},
        { title: "Email", internal: "email", filter: true, filterInternal: 'email' },
        { title: "Address", internal: "address", filter: true, filterInternal: 'address' },
        { title: "Orders", internal: "user_sales", filter: true, filterInternal: 'user_sales' },
        { title: "Last Order", internal: "last_order_timestamp", filter: true, dateFilter: true, filterInternal: 'last_order_timestamp', code: (row, data) => data ? formatTimestamp(new Date(data)) : "No orders yet"},
        { title: "District", internal: "district.name", filter: true, filterInternal: 'district' },
        { title: "Actions", internal: 'id', code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-end'}>
                        <div style={{cursor: 'pointer'}} onClick={() => {setSelectedId(row.user); setShowDelete(true)}}>
                            <FontAwesomeIcon icon={faTrash} color={'#F00'}/>
                        </div>
                    </div>
                )
            }
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <DeleteModal show={showDelete} setter={setShowDelete} onClick={() => deleteObj(selectedId)}/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'user_data_secure'}
                    pull={setRows}
                    noPagination={true}
                    title={'users'}
                    cols={cols}
                    rows={rows}
                    noCreate={true}
                />
            </div>
        </div>
    );
}
