import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import myContext from "../../../MyContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { formatPrice } from "../../../utils/utils";

export default function ProductsList() {
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const permissions = parseInt(localStorage.getItem("permissions"));
  const warehouse_id = parseInt(localStorage.getItem("warehouse_id"));

  const deleteObj = (id) => {
    axiosInstance.delete("product/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const { setProduct } = useContext(myContext);
  const navigate = useNavigate();
  const editObj = (data) => {
    navigate("/admin/products/create", { state: data });
  };

  const addPromotion = (id) => {
    setProduct(id);
    navigate("/admin/promotions/create");
  };

  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  useEffect(() => {
    let tempCols = [];

    tempCols.push({ title: "ID", internal: "id" });
    tempCols.push({ title: "Image", image: { url: "image.image" } });
    tempCols.push({ title: "Name", internal: "name", filter: true });
    if (permissions === 1)
      tempCols.push({
        title: "Subcategory",
        internal: "subcategory_name",
        filter: true,
      });
    if (permissions === 1)
      tempCols.push({
        title: "Cost",
        internal: "cost",
        code: (row) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>{formatPrice(row.cost)}</div>
          );
        },
      });
    tempCols.push({
      title: "Price",
      internal: "price",
      code: (row, data) => {
        return <div style={{ whiteSpace: "nowrap" }}>{formatPrice(data)}</div>;
      },
    });
    if (permissions === 1)
      tempCols.push({
        title: "Supplier",
        internal: "supplier_name",
        filter: true,
        filterInternal: "biller",
      });
    if (permissions === 1 || warehouse_id === 1)
      tempCols.push({
        title: "Ngor",
        internal: "warehouse_quantities.ngor",
        code: (row, data) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {data ? data : 0}/{row.stock_1 !== data ? data : row.stock_1}
            </div>
          );
        },
      });
    if (permissions === 1 || warehouse_id === 2)
      tempCols.push({
        title: "Plateau",
        internal: "warehouse_quantities.plateau",
        code: (row, data) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {data ? data : 0}/{row.stock_2 !== data ? data : row.stock_2}
            </div>
          );
        },
      });
    // if(permissions === 1 || warehouse_id === 4) tempCols.push({ title: "Dépôt", internal: "warehouse_quantities.depot", code: (row, data) => {
    //     return (
    //         <div style={{whiteSpace: 'nowrap'}}>
    //             {data ? data : 0}/{row.stock_4}
    //         </div>)
    //     } });
    if (permissions === 1 || warehouse_id === 3)
      tempCols.push({
        title: "Point E",
        internal: "warehouse_quantities.point_e",
        code: (row, data) => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              {data ? data : 0}/{row.stock_3 !== data ? data : row.stock_3}
            </div>
          );
        },
      });
    if (permissions === 1)
      tempCols.push({
        title: "Alert Quantity",
        internal: "alert_quantity",
        filter: true,
      });
    if (permissions === 1)
      tempCols.push({
        title: "Show",
        internal: "display",
        filter: true,
        filterInternal: "show_filter",
        isBoolean: true,
      });
    if (permissions === 1)
      tempCols.push({
        title: "Actions",
        internal: "id",
        code: (row, data) => {
          return (
            <div className={"d-flex justify-content-between"}>
              <div role={"button"} onClick={() => addPromotion(row)}>
                <FontAwesomeIcon icon={faPlus} color={"#9EA3AC"} />
              </div>

              <div role={"button"} onClick={() => editObj(data)}>
                <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
              </div>

              <div
                role={"button"}
                onClick={() => {
                  setShowDelete(true);
                  setSelectedId(data);
                }}
              >
                <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
              </div>
            </div>
          );
        },
      });

    setCols(tempCols);
  }, []);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          defaultFilters={{
            quantity: -1,
            no_combos: true,
            simple_serializer: true,
            max_query: 200,
          }}
          fetchUrl={"product"}
          title={"products"}
          cols={cols}
          rows={rows}
          withActions={permissions === 1}
          withImport={permissions === 1}
          noCreate={permissions !== 1}
          pull={setRows}
          limit={200}
        />
      </div>
    </div>
  );
}
