import React, { useEffect, useState } from "react";
import styles from "./product_card.module.css";
import { formatPrice, getDiscountPercentage } from "../../../utils/utils";
import axiosInstance from "../../../AxiosInstance";
import { faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";
import Loading from "../../../admin/components/loading";

export default function ProductCard({
  product,
  setRefreshCart,
  cartItems,
  onClick,
  setShowLogin,
}) {
  const district = JSON.parse(localStorage.getItem("district"));
  const [quantity, setQuantity] = useState(0);
  const [adding, setAdding] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [qteUpdating, setQteUpdating] = useState(false);

  const addToCart = () => {
    setAdding(true);
    axiosInstance
      .post(`cart/add/${product.id}/`, {
        quantity: 1,
        district_id: district.id,
      })
      .then((response) => {
        if (response?.response?.status === 500) {
          setShowLogin((prev) => prev + 1);
        }
        setRefreshCart((prev) => prev + 1);

        const data = response.data;
        let found = false;
        data?.items
          ?.filter((item) => item.product.id === product.id)
          .map((item) => {
            found = true;
          });
        if (!found) {
          toast("Quantité maximale ajoutée au panier!", ToastTypes.ERROR);
        }
        setAdding(false);
      });
  };

  const modifyQuantity = (new_quantity) => {
    setUpdating(true);
    const previousQuantity = quantity;
    axiosInstance
      .post(`cart/add/${product.id}/`, {
        quantity: new_quantity,
        district_id: district.id,
        is_restaurant: false,
      })
      .then((response) => {
        if (response.status === 200) {
          let found = false;
          const productIds = response.data?.items.map((item) => {
            return {
              product_id: item.product.id,
              quantity: item.qty,
            };
          });

          productIds
            ?.filter((item) => item.product_id === product.id)
            .map((item) => {
              found = true;
              if (previousQuantity === item.quantity) {
                //setShowError(true);
                toast("Quantité maximale ajoutée au panier!", ToastTypes.ERROR);
              }
              setQuantity(item.quantity);
            });

          //setQuantity((prev) => prev + new_quantity);
        }
        setRefreshCart((prev) => prev + 1);
        setUpdating(false);
      });
  };

  useEffect(() => {
    let found = false;
    cartItems
      ?.filter((item) => item.product.id === product.id)
      .map((item) => {
        setQuantity(item.qty);
        found = true;
      });
    if (!found) {
      setQuantity(0);
    }
  }, [cartItems]);

  return (
    <div className={styles.container}>
      <div onClick={onClick}>
        <img
          src={product?.image ? product.image.image : "/placeholder.jpg"}
          alt={product.name}
          className={styles.productImage}
        />
        <div className={styles.productInfo}>
          {product.promotion_price && (
            <div
              className={"d-flex align-items-center justify-content-start"}
              style={{ fontSize: "13px", whiteSpace: "nowrap" }}
            >
              <div style={{ textDecoration: "line-through" }}>
                {formatPrice(product.price)} FCFA
              </div>
              <div className={`${styles.promotionPercentage} px-1 ms-1`}>
                {getDiscountPercentage(product.price, product.promotion_price)}%
              </div>
            </div>
          )}
          <div className={styles.productPrice}>
            {formatPrice(
              product.promotion_price ? product.promotion_price : product.price
            )}{" "}
            FCFA
          </div>
          <div className={styles.productName}>{product.name}</div>
        </div>
      </div>
      {quantity === 0 ? (
        <div className={styles.addButton} onClick={() => addToCart()}>
          {adding ? (
            <div className="spinner"></div>
          ) : (
            <FontAwesomeIcon icon={faPlus} size={"xs"} />
          )}
        </div>
      ) : (
        <div className={`${styles.quantityButtons}`}>
          <div
            className={"ps-2"}
            style={{
              flexBasis: "40%",
              cursor: "pointer",
              textAlign: "left",
              opacity: updating ? 0.5 : 1,
              pointerEvents: updating ? "none" : "auto",
            }}
            onClick={() => (updating ? null : modifyQuantity(-1))}
          >
            -
          </div>
          {updating ? <div className="spinner"></div> : <div>{quantity}</div>}

          <div
            className={"pe-2"}
            style={{
              flexBasis: "40%",
              cursor: "pointer",
              textAlign: "right",
              opacity: updating ? 0.5 : 1,
              pointerEvents: updating ? "none" : "auto",
            }}
            onClick={() => (updating ? null : modifyQuantity(1))}
          >
            +
          </div>
        </div>
      )}
    </div>
  );
}
