import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";

export default function ComboList() {
  const [rows, setRows] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const navigate = useNavigate();

  const deleteObj = (id) => {
    axiosInstance.delete("product/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const editObj = (data) => {
    navigate("/admin/combos/create", { state: data });
  };

  const cols = [
    { title: "Image", image: { url: "image.image" } },
    { title: "Name", internal: "name", filter: true },
    { title: "Subcategory", internal: "subcategory.name" },
    { title: "Cost", internal: "cost", filter: true },
    { title: "Price", internal: "price", filter: true },
    { title: "Ngor", internal: "combo_warehouse_quantities.ngor" },
    { title: "Plateau", internal: "combo_warehouse_quantities.plateau" },
    { title: "Point E", internal: "combo_warehouse_quantities.point_e" },
    { title: "Sold", internal: "" },
    {
      title: "Actions",
      internal: "id",
      code: (row, data) => {
        return (
          <div className={"d-flex justify-content-between"}>
            <div role={"button"} onClick={() => editObj(row)}>
              <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              onClick={() => {
                setShowDelete(true);
                setSelectedId(data);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          defaultFilters={{ combos: true }}
          title={"combos"}
          fetchUrl={`product`}
          withImport={false}
          cols={cols}
          rows={rows}
          pull={setRows}
        />
      </div>
    </div>
  );
}
