import React, {useContext, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import myContext from "../../../MyContext";
import {useNavigate} from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";

export default function BillerProductsList() {
    const {setProduct} = useContext(myContext);
    const navigate = useNavigate();
    const [showDelete, setShowDelete] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const deleteObj = (id) => {
        axiosInstance.delete('product/' + id + '/').then((response) => {
            window.location.reload();
        })
    }

    const editObj = (data) => {
        navigate('/admin/billerproducts/create', {state: data});
    }

    const addPromotion = (id) => {
        setProduct(id);
        navigate('/admin/promotions/create');
    }

    const [rows, setRows] = useState([]);
    const cols = [
        { title: "Image", image: { url: "image.image" } },
        { title: "Name", internal: "name", filter: true },
        { title: "Description", internal: "description" },
        { title: "Price", internal: "price", filter: true },
        { title: "Biller", internal: "", filterInternal: 'supplier', filter: true, code: (row, data) => {
            return row.supplier_name ? row.supplier_name : row.subcategory?.supplier?.name;
            } },
        { title: "Sold", internal: "" },
        { title: "Show", internal: "display", filter: true, filterInternal: 'show_filter', isBoolean: true },
        {
            title: "Actions",
            internal: 'id',
            code: (row, data) => {
                return (
                    <div className={'d-flex justify-content-between'}>
                        <div role={'button'} onClick={() => {
                            setShowDelete(true);
                            setSelectedId(row.id);
                        }}>
                            <FontAwesomeIcon icon={faTrash} color={'#EF4444'}/>
                        </div>

                        <div role={'button'} onClick={() => editObj(row)}>
                            <FontAwesomeIcon icon={faPenToSquare} color={'#9EA3AC'}/>
                        </div>

                        <div role={'button'} onClick={() => addPromotion(row)}>
                            <FontAwesomeIcon icon={faPlus} color={'#9EA3AC'}/>
                        </div>
                    </div>
                );
            }
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <DeleteModal show={showDelete} setter={setShowDelete} onClick={() => deleteObj(selectedId)}/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    defaultFilters={{"only_supplier": 1}}
                    fetchUrl={'product'}
                    title={'Biller Products'}
                    cols={cols}
                    rows={rows}
                    withActions={true}
                    withImport={true}
                    pull={setRows}
                />
            </div>
        </div>
    )
}