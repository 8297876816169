import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  BigTextInput,
  FileInput,
  PrimaryButton,
  RadioInput,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation } from "react-router-dom";
import inputstyles from "../../components/styles/inputs.module.css";

export default function BillerProductsCreate() {
  const [form, setForm] = useState({});
  const [userData, setUserData] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const { state } = useLocation();
  const [variants, setVariants] = useState([]);
  const [startName, setStartName] = useState(state?.name);
  const [removedVariants, setRemovedVariants] = useState([]);
  const [removedItems, setRemovedItems] = useState([]);

  const [billers, setBillers] = useState([]);

  useEffect(() => {
    let displayForm = 0;
    let unlimitedForm = 0;
    if (state) {
      console.log({ state });
      axiosInstance.get(`product/${state.id}/`).then((response) => {
        let retrieved = response.data;
        if (state.biller) state.supplier = state.biller.id;
        displayForm = retrieved.display ? 1 : 0;
        unlimitedForm = retrieved.unlimited ? 1 : 0;
        setForm({
          ...state,
          is_combo: 0,
          display: displayForm,
          unlimited: unlimitedForm,
        });

        axiosInstance
          .get(`variants/?product_id=${state.id}`)
          .then((response) => {
            setVariants(response.data);
          });
      });
    }

    axiosInstance.get("current_user/").then((response) => {
      setUserData(response.data);

      if (response.data?.user_data?.supplier === null) {
        axiosInstance.get("simple/supplier/").then((billers_response) => {
          setBillers(billers_response.data);
          if (state) {
            setForm({
              ...state,
              is_combo: 0,
              display: displayForm,
              unlimited: unlimitedForm,
              selected_biller: state?.supplier
                ? state?.supplier
                : state?.subcategory?.supplier?.id,
            });
          }
        });
      } else {
        axiosInstance
          .get(`subcategory/?biller_id=${response.data?.user_data?.supplier}`)
          .then((response) => {
            setSubcategories(response.data.results);
          });
      }
    });
  }, []);

  const defaultBiller = billers?.find(
    (item) => item.id === form?.selected_biller
  );

  useEffect(() => {
    if (form["selected_biller"] !== undefined) {
      axiosInstance
        .get(`subcategory/?biller_id=${form["selected_biller"]}`)
        .then((response) => {
          setSubcategories(response.data.results);
        });
    }
  }, [form["selected_biller"]]);

  const back = () => {
    window.location.replace("/admin/billerproducts");
  };

  const submit = () => {
    let formData = new FormData();
    console.log(form);
    for (const key in form) {
      formData.append(key, form[key]);
    }
    formData.append("variants", JSON.stringify(variants));
    formData.append("removed_variants", JSON.stringify(removedVariants));
    formData.append("removed_items", JSON.stringify(removedItems));
    formData.append("is_warehouse_product", false);

    if (state) {
      axiosInstance
        .put("product/" + state.id + "/", formData)
        .then((response) => {
          back();
        });
    } else {
      axiosInstance.post("product/", formData).then((response) => {
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>
            {state ? "Edit" : "Create"} Biller Product
          </div>
          <div>
            Add your product description and necessary information from here
          </div>
          {console.log("selected_biller", form?.selected_biller)}
        </div>
        <div className={styles.crudInputs}>
          <FileInput name={"image"} setForm={setForm} form={form} />
          <div className={`${styles.createContainer} p-4`}>
            {/* {billers && <SelectInput name={'Biller'} internalName={'selected_biller'} options={billers} setForm={setForm} form={form}/>} */}
            <SelectInput
              name={"Biller"}
              internalName={"selected_biller"}
              options={billers}
              setForm={setForm}
              form={form}
              defaultValue={defaultBiller}
            />
            <div className={"mt-2"} />
            <TextInput name={"name"} setForm={setForm} form={form} />
            <div className={"mt-2"} />
            <BigTextInput name={"description"} setForm={setForm} form={form} />
            <TextInput name={"price"} setForm={setForm} form={form} />
            <div className={"mt-2"} />
            <TextInput name={"slug"} setForm={setForm} form={form} />
            <div className={"mt-2"} />
            <RadioInput
              name={"display"}
              options={["No", "Yes"]}
              setForm={setForm}
              form={form}
            />

            <div>
              {variants.map((variant, index) => (
                <div key={index}>
                  <div className="form-group mb-3">
                    <div className={`${inputstyles.inputLabel} mb-1`}>
                      Variant Name
                    </div>
                    <input
                      type={"text"}
                      className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                      id={`variant_name_${index}`}
                      name="name"
                      value={variant.name}
                      onChange={(event) => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index] = {
                            ...prevVariants[index],
                            name: event.target.value,
                          };
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className={`${inputstyles.inputLabel} mb-1`}>
                      Variant Min
                    </div>
                    <input
                      type={"text"}
                      className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                      id={`variant_min_quantity_${index}`}
                      name="min_quantity"
                      value={variant.min_quantity}
                      onChange={(event) => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index] = {
                            ...prevVariants[index],
                            min_quantity: event.target.value,
                          };
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>

                  <div className="form-group mb-3">
                    <div className={`${inputstyles.inputLabel} mb-1`}>
                      Variant Max
                    </div>
                    <input
                      type={"text"}
                      className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                      id={`variant_max_quantity_${index}`}
                      name="max_quantity"
                      value={variant.max_quantity}
                      onChange={(event) => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index] = {
                            ...prevVariants[index],
                            max_quantity: event.target.value,
                          };
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>

                  <div>
                    {variant.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        className={"d-flex justify-content-between"}
                      >
                        <div className="form-group mb-3">
                          <div className={`${inputstyles.inputLabel} mb-1`}>
                            Item Name
                          </div>
                          <input
                            type={"text"}
                            className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                            id={`variant_item_name_${index}_${itemIndex}`}
                            name="name"
                            value={item.name}
                            onChange={(event) => {
                              setVariants((prevVariants) => {
                                const updatedVariants = [...prevVariants];
                                updatedVariants[index].items[itemIndex] = {
                                  ...prevVariants[index].items[itemIndex],
                                  name: event.target.value,
                                };
                                return updatedVariants;
                              });
                            }}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <div className={`${inputstyles.inputLabel} mb-1`}>
                            Item Price
                          </div>
                          <input
                            type={"text"}
                            className={`${inputstyles.inputBorderDefault} form-control rounded-2`}
                            id={`variant_item_price_${index}_${itemIndex}`}
                            name="price"
                            value={item.price}
                            onChange={(event) => {
                              setVariants((prevVariants) => {
                                const updatedVariants = [...prevVariants];
                                updatedVariants[index].items[itemIndex] = {
                                  ...prevVariants[index].items[itemIndex],
                                  price: event.target.value,
                                };
                                return updatedVariants;
                              });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={"d-flex justify-content-end"}>
                    <SecondaryButton
                      name={"Add Item"}
                      onClick={() => {
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index].items.push({
                            name: "",
                            price: 0,
                          });
                          return updatedVariants;
                        });
                      }}
                    />
                    <div className={"pe-2"} />
                    <SecondaryButton
                      name={"Remove Item"}
                      onClick={() => {
                        const currentVariant = variants[index];
                        const itemId =
                          currentVariant.items[currentVariant.items.length - 1]
                            .id;
                        setRemovedItems((prev) => [...prev, itemId]);
                        setVariants((prevVariants) => {
                          const updatedVariants = [...prevVariants];
                          updatedVariants[index].items.pop();
                          return updatedVariants;
                        });
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={"d-flex justify-content-end mt-3"}>
              <SecondaryButton
                name={"Add Variant"}
                onClick={() => {
                  setVariants((prevVariants) => [
                    ...prevVariants,
                    { name: "", min_quantity: 1, max_quantity: 1, items: [] },
                  ]);
                }}
              />
              <div className={"pe-2"} />
              <SecondaryButton
                name={"Remove Variant"}
                onClick={() => {
                  const id = variants[variants.length - 1].id;
                  setRemovedVariants((prev) => [...prev, id]);
                  setVariants((prevVariants) => {
                    const updatedVariants = [...prevVariants];
                    updatedVariants.pop();
                    return updatedVariants;
                  });
                }}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update" : "Add Product"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
