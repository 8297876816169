import React, { useEffect, useRef, useState } from "react";
import mystyles from "./styles/table.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../AxiosInstance";

export default function TableComponent({
  fetchUrl,
  cols,
  rows,
  pull,
  withIndex,
  noPagination,
  defaultFilters,
}) {
  const [activeFilter, setActiveFilter] = useState(null);
  const filterInputRef = useRef(null);
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [firstLoad, setFirstLoad] = useState(true);
  const [timer, setTimer] = useState(null);

  const setField = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const getValueFromString = (object, accessString) => {
    let accessParts = accessString.split(".");
    let value = object;

    for (let i = 0; i < accessParts?.length; i++) {
      if (!value) {
        return "";
      }
      let accessPart = accessParts[i];
      value = value[accessPart];
    }

    return value;
  };

  const renderTh = (col, index) => {
    if (index === activeFilter && col.filter === true) {
      return (
        <th key={index}>
          <div ref={filterInputRef} className={"d-flex"}>
            <input
              value={
                col.filterInternal
                  ? filters[col.filterInternal]
                  : filters[col.internal]
              }
              onChange={(event) =>
                setField(
                  col.filterInternal ? col.filterInternal : col.internal,
                  event.target.value
                )
              }
              type={col.dateFilter ? "date" : "text"}
              placeholder={`Filter ${col.title}`}
            />
            {col.dateFilter && (
              <input
                value={
                  col.filterInternal
                    ? filters[`${col.filterInternal}_end`]
                    : filters[`${col.internal}_end`]
                }
                onChange={(event) =>
                  setField(
                    col.filterInternal
                      ? `${col.filterInternal}_end`
                      : `${col.internal}_end`,
                    event.target.value
                  )
                }
                type={"date"}
                placeholder={`Filter ${col.title}`}
              />
            )}
          </div>
        </th>
      );
    } else {
      return (
        <th key={index} onClick={() => setActiveFilter(index)}>
          {col.title}{" "}
          {(col.filterInternal
            ? filters[col.filterInternal]
            : filters[col.internal]) && "*"}
        </th>
      );
    }
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      return;
    }

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      if (filters) {
        const queryParams = Object.entries(filters)
          ?.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
        const url = `${fetchUrl}/?${queryParams}`;
        axiosInstance.get(url).then((response) => {
          if (noPagination) {
            if (pull !== undefined) pull(response?.data);
          } else {
            if (pull !== undefined) pull(response?.data?.results);
          }
        });
      }
    }, 400);

    setTimer(newTimer);
  }, [filters]);

  return (
    <table className={`${mystyles.table} table table-hover`}>
      <thead>
        <tr>
          {withIndex && <th>#</th>}
          {cols?.map((col, index) => renderTh(col, index))}
        </tr>
      </thead>
      <tbody>
        {rows?.map((row, index) => (
          <tr key={index}>
            {withIndex && <td>{index + 1}</td>}
            {cols?.map((col, colIndex) => (
              <td key={colIndex}>
                {col.buttons ? (
                  <span className={mystyles.actionButtons}>
                    {col.buttons?.map((button, buttonIndex) => (
                      <div
                        key={buttonIndex}
                        role={"button"}
                        onClick={() => {
                          button.onClick(row.id);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={button.icon}
                          key={buttonIndex}
                          color={button.color}
                        />
                      </div>
                    ))}
                  </span>
                ) : (
                  <>
                    {col.image ? (
                      <img
                        src={getValueFromString(row, col.image.url)}
                        alt={col.title}
                        width={"50px"}
                        height={"50px"}
                        style={{
                          borderRadius: "5px",
                          objectFit: "contain",
                          backgroundColor: "#F7F8F9",
                        }}
                      />
                    ) : (
                      <>
                        {col.isBoolean ? (
                          <span>
                            {getValueFromString(row, col.internal)
                              ? "Yes"
                              : "No"}
                          </span>
                        ) : (
                          <>
                            {col.code ? (
                              <>
                                {col.code(
                                  row,
                                  getValueFromString(row, col.internal)
                                )}
                              </>
                            ) : (
                              <span>
                                {getValueFromString(row, col.internal)}
                              </span>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
