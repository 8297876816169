// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout_container__CKfWj {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
}

.checkout_boxContainer__E8Tv9 {
    margin-top: 80px;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
}

.checkout_orderButton__jzqzk {
    background-color: #009F7F;
    border-radius: 40px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shop/checkout/checkout.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;IACzC,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB","sourcesContent":[".container {\n    overflow-x: hidden;\n    display: flex;\n    justify-content: center;\n}\n\n.boxContainer {\n    margin-top: 80px;\n    padding: 10px;\n    background-color: #FFFFFF;\n    border-radius: 6px;\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n    text-align: left;\n}\n\n.orderButton {\n    background-color: #009F7F;\n    border-radius: 40px;\n    color: #FFFFFF;\n    font-weight: 600;\n    font-size: 1.2rem;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `checkout_container__CKfWj`,
	"boxContainer": `checkout_boxContainer__E8Tv9`,
	"orderButton": `checkout_orderButton__jzqzk`
};
export default ___CSS_LOADER_EXPORT___;
