import React, {useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import {
    BigTextInput,
    FileInput,
    PrimaryButton,
    SecondaryButton,
    TextInput
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import {useLocation} from "react-router-dom";

export default function BlogCreate() {
    const [form, setForm] = useState({});
    const {state} = useLocation();

    useEffect(() => {
        if(state) {
            setForm(state);
        }
    }, []);

    const back = () => {
        window.location.replace('/admin/blog');
    }

    const submit = () => {
        let formData = new FormData();
        for(const key in form) {
            formData.append(key, form[key]);
        }

        if(state) {
            axiosInstance.put('blog/' + state.id + '/', formData).then((response) => {
                back();
            });
        }
        else {
            axiosInstance.post('blog/', formData).then((response) => {
                back();
            });
        }
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>{state ? 'Update' : 'Create'} Entry</div>
                    <div>Add your entry description and necessary information from here</div>
                </div>
                <div className={styles.crudInputs}>
                    <FileInput name={'image'} form={form} setForm={setForm}/>
                    <div className={`${styles.createContainer} p-4`}>
                        <TextInput name={'title'} setForm={setForm} form={form}/>
                        <BigTextInput name={'text'} setForm={setForm} form={form}/>
                    </div>

                    <div className={styles.buttonContainer}>
                        <SecondaryButton name={'Back'} onClick={back}/>
                        <div className={'ps-3'}/>
                        <PrimaryButton name={`${state ? 'Update' : 'Create'} Entry`} onClick={submit}/>
                    </div>
                </div>
            </div>
        </div>
    );
}