import React, { useContext, useEffect, useRef, useState } from "react";
import mystyles from "./styles/table.module.css";
import { Link, useNavigate } from "react-router-dom";
import MyContext from "../../MyContext";
import axiosInstance from "../../AxiosInstance";
import {
  toast,
  ToastTypes,
} from "../../components/ajonjolib/toasts/toast/toast";

export default function TableHeader({ title, noCreate, withImport, fetchUrl }) {
  const [createUrl, setCreateUrl] = useState("");
  const fileRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [lastProductProcessed, setLastProductProcessed] = useState(null);

  useEffect(() => {
    let url = "/admin/" + title + "/create";
    url = url.replace(/\s/g, "");
    setCreateUrl(url);
  }, []);

  const navigate = useNavigate();
  const { setEditing } = useContext(MyContext);

  const goto = () => {
    setEditing({});
    navigate(createUrl);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    axiosInstance
      .post(
        `${fetchUrl}/excel/`,
        {
          file: files[0],
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast("Data imported successfully", ToastTypes.SUCCESS);
          setTimeout(() => {
            setLastProductProcessed(null);
            window.location.reload();
          }, 500);
        } else {
          setLastProductProcessed(response.response.data);
          toast("Error during import", ToastTypes.ERROR);
        }
      });
  };

  return (
    <div
      className={`d-flex flex-row justify-content-between shadow-sm rounded-2 py-3 px-3 ${mystyles.header}`}
    >
      <div className={mystyles.title}>{title}</div>
      <div className={`d-flex flex-row`}>
        {lastProductProcessed && (
          <div
            style={{
              fontWeight: "bold",
              color: "#F00",
              marginRight: "10px",
            }}
          >
            Last Import: {lastProductProcessed}
          </div>
        )}
        {!noCreate && (
          <div onClick={goto} className={`${mystyles.button} me-3`}>
            Add +
          </div>
        )}
        {withImport && (
          <div
            className={mystyles.button}
            onClick={() => {
              fileRef.current.click();
            }}
          >
            Import
          </div>
        )}
        <input
          ref={fileRef}
          type={"file"}
          accept={".csv,.xlsx"}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}
