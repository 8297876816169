import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function PaymentsList() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false); // Loader pour la liste
  const [activeLoading, setActiveLoading] = useState({}); // Loader pour chaque paiement (active/inactive)

  useEffect(() => {
    setLoading(true); // Démarre le loader
    axiosInstance
      .get("payment-methods/")
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          // Update to map the response data correctly
          const formattedData = response.data.map((method) => ({
            id: method.id,
            name: method.name,
            is_active: method.is_active ? "Oui" : "Non", // Convert boolean to a more user-friendly string
          }));
          setRows(formattedData);
        }
      })
      .finally(() => setLoading(false)); // Arrête le loader
  }, []);

  // Fonction pour gérer l'activation/désactivation d'un paiement
  const handleToggleActive = (id, isActive, name) => {
    setActiveLoading({ ...activeLoading, [id]: true }); // Démarre le loader pour ce paiement

    axiosInstance
      .post(`/update-payment-method/${id}/`, {
        is_active: !isActive,
        name: name,
      })
      .then((response) => {
        // Met à jour la liste après modification
        setRows((prevRows) =>
          prevRows.map((row) =>
            row.id === id
              ? { ...row, is_active: isActive ? "Non" : "Oui" }
              : row
          )
        );
      })
      .finally(() => setActiveLoading({ ...activeLoading, [id]: false })); // Arrête le loader
  };

  const cols = [
    { title: "Nom", internal: "name" },
    {
      title: "Est actif",
      internal: "is_active",
      code: (data, row) => (row ? "Oui" : "Non"),
    },

    // {
    //   title: "Actions",
    //   internal: "id",
    //   code: (data) => {
    //     const isLoading = activeLoading[data.id]; // Check if the action is loading for this specific row

    //     return (
    //       <div className={"d-flex justify-content-center"}>
    //         <input
    //           type="checkbox"
    //           checked={data.is_active === "Oui"} // Assuming "Oui" represents active status
    //           onChange={() => {
    //             if (!isLoading) {
    //               // Prevent toggling if already loading
    //               handleToggleActive(
    //                 data.id,
    //                 data.is_active === "Oui",
    //                 data.name
    //               );
    //             }
    //           }}
    //           disabled={isLoading} // Disable checkbox while loading
    //         />
    //         {isLoading && (
    //           <FontAwesomeIcon
    //             icon={faSpinner}
    //             spin
    //             color="#9EA3AC"
    //             className="ms-2"
    //           />
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        {loading ? ( // Affiche le loader global
          <div style={{ textAlign: "center" }}>
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          </div>
        ) : (
          <TableView
            noCreate={true}
            fetchUrl={"payment-methods"}
            title={"Payments"}
            cols={cols}
            rows={rows}
            pull={setRows}
          />
        )}
      </div>
    </div>
  );
}
