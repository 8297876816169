import React, { useEffect, useState } from "react";
import TableComponent from "./table";
import TableHeader from "./table_header";
import Loading from "./loading";
import PaginationAdmin from "./pagination/pagination";

export default function TableView({
  children,
  fetchUrl,
  title,
  pull,
  noCreate,
  noPagination,
  cols,
  rows,
  withIndex,
  withImport,
  defaultFilters,
  limit,
}) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (rows?.length !== 0) {
      setIsLoading(false);
    }
  }, [rows]);

  return (
    <div>
      <TableHeader
        title={title}
        noCreate={noCreate}
        withImport={withImport}
        fetchUrl={fetchUrl}
      />

      {children}

      <div className={"my-3"}>
        <div style={{ height: "65px" }}>
          <PaginationAdmin
            prop={fetchUrl}
            func={pull}
            limit={limit ? limit : 30}
            defaultFilters={defaultFilters}
          />
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div
            style={{
              overflowX: "scroll",
            }}
          >
            <TableComponent
              fetchUrl={fetchUrl}
              defaultFilters={defaultFilters}
              noPagination={noPagination}
              pull={pull}
              cols={cols}
              rows={rows}
              withIndex={withIndex}
            />
          </div>
        )}
      </div>
    </div>
  );
}
