import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faBackwardStep,
  faCheck,
  faEye,
  faForwardStep,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatTimestamp, getPaimentColor } from "../../components/utils";
import { Modal as BootStrapModal } from "react-bootstrap";
import SaleDetailModal from "../closures/sale_detail/sale_detail";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/inputs";
import modalstyles from "../../components/modal/modal.module.css";
import { formatPrice } from "../../../utils/utils";
import WaveTransactions from "./wave_transactions";

export default function SalesApi() {
  const [rows, setRows] = useState([]);
  const [showSale, setShowSale] = useState(false);
  const [selectedSale, setSelectedSale] = useState({});
  const [showCancel, setShowCancel] = useState(false);
  const permissions = parseInt(localStorage.getItem("permissions"));
  const [showUnCancel, setShowUnCancel] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const lastSaleId = rows.length > 0 ? rows[0].id : 0;
      axiosInstance
        .get(`sale/sale_api/?all=${true}&starting_sale_id=${lastSaleId}`)
        .then((response) => {
          if (response.status === 200) {
            setRows([...response.data.results, ...rows]);
          }
        });
    }, 35000);

    return () => clearInterval(interval);
  }, [rows]);

  const getStatus = (status) => {
    if (status === 0)
      return (
        <div
          style={{
            backgroundColor: "#fa9f43",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          Pending
        </div>
      );
    else if (status === 1)
      return (
        <div
          style={{
            backgroundColor: "#eeda53",
            textAlign: "center",
            borderRadius: "6px",
            color: "#fff",
          }}
        >
          In Preparation
        </div>
      );
    else if (status === 2)
      return (
        <div
          style={{
            backgroundColor: "#439ffa",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          In Delivery
        </div>
      );
    else if (status === 4)
      return (
        <div
          style={{
            backgroundColor: "#41d935",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          Completed
        </div>
      );
    else if (status === 5)
      return (
        <div
          style={{
            backgroundColor: "#f34933",
            textAlign: "center",
            borderRadius: "6px",
            color: "#FFF",
          }}
        >
          Cancelled
        </div>
      );
    return "Unknown";
  };

  const view = (data) => {
    console.log(data);
    setShowSale(true);
    setSelectedSale(data);
  };

  const cols = [
    {
      title: "Date",
      internal: "date",
      filter: true,
      dateFilter: true,
      code: (data, row) => formatTimestamp(row),
    },
    { title: "Reference Number", internal: "reference", filter: true },
    {
      title: "Client",
      internal: "user.user_data",
      filter: true,
      filterInternal: "user",
      code: (data, row) => row.name + " " + row.lastname,
    },
    { title: "Orders", internal: "user_sales", filter: true },
    {
      title: "Warehouse",
      internal: "warehouse.name",
      filter: true,
      filterInternal: "warehouse",
      code: (row, data) => {
        return row.supplier ? "-" : data;
      },
    },
    {
      title: "Biller",
      internal: "supplier.name",
      filter: true,
      filterInternal: "supplier",
      code: (row, data) => {
        return row.supplier ? data : "-";
      },
    },
    {
      title: "Total",
      internal: "sub_total",
      code: (data, row) => {
        let total_variant_price = 0;
        data.items.forEach((item) => {
          let item_variant_price = 0;
          item.variant_items.forEach((variant) => {
            item_variant_price += variant.variant_item.price * item.qty;
          });
          total_variant_price += item_variant_price;
        });
        return formatPrice(data.sub_total + data.fee + total_variant_price);
      },
    },
    {
      title: "Status",
      internal: "status",
      code: (data, row) => getStatus(parseInt(row)),
      // filter: true,
    },
    {
      title: "Paiment",
      internal: "paiment_mode",
      code: (data, row) => getPaimentColor(data.paiment_mode),
    },

    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        const not_today =
          new Date(data.date).toDateString() !== new Date().toDateString();
        return (
          <div className={"d-flex justify-content-between"}>
            <div role={"button"} onClick={() => view(data)}>
              <FontAwesomeIcon icon={faEye} color={"#9EA3AC"} />
            </div>

            {!not_today && (
              <div
                role={"button"}
                onClick={() => {
                  setSelectedSale(data);
                  setShowUnCancel(true);
                }}
              >
                <FontAwesomeIcon icon={faCheck} color={"#44ef94"} />
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const cancel = () => {
    axiosInstance
      .post("sale/" + selectedSale?.id + "/cancel/", {
        reason: "",
        detail: "",
      })
      .then((response) => {
        window.location.reload();
      });
  };
  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <BootStrapModal show={showUnCancel} centered={true}>
        <div className={modalstyles.header}>
          <div>Uncancel Sale</div>
          <div>Are you sure?</div>
        </div>

        <div className={modalstyles.footer}>
          <SecondaryButton
            onClick={() => setShowUnCancel(false)}
            name={"Back"}
          />
          <div className={"ms-3"}>
            <DangerButton name={"Uncancel Sale"} onClick={() => cancel()} />
          </div>
        </div>
      </BootStrapModal>
      <BootStrapModal show={showSale} centered={true} size={"lg"}>
        <div>
          <div style={{ padding: "16px" }}>
            <div
              className={"mb-2"}
              style={{ fontWeight: "600", fontSize: "20px", color: "#334155" }}
            >
              Sale Details
            </div>
            <SaleDetailModal data={selectedSale} />
          </div>
          <div className={styles.footer}>
            <div className={"ms-3"}>
              <PrimaryButton
                name={"Close"}
                onClick={() => setShowSale(false)}
              />
            </div>
          </div>
        </div>
      </BootStrapModal>

      <div className={`${styles.container} me-4`}>
        <TableView
          noCreate={true}
          defaultFilters={{ all: true, status: 5 }} // Set default filter for cancelled sales
          fetchUrl={"sale/sale_api"} // Use the URL for cancelled sales
          title={"Api Sales"}
          cols={cols}
          rows={rows}
          pull={setRows}
        />
      </div>
      <WaveTransactions />
    </div>
  );
}
