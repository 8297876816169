import React, {useEffect, useState} from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import axiosInstance from "../../../AxiosInstance";
import {formatTimestamp} from "../../components/utils";

export default function StockHistory() {
    const [rows, setRows] = useState([]);
    const cols = [
        {title: "Product", internal: "stock.product.name"},
        {title: "Warehouse", internal: "stock.warehouse.name"},
        {title: "Previous Quantity", internal: "previous_quantity"},
        {title: "New Quantity", internal: "new_quantity"},
        {title: "Reason", internal: "reason"},
        {title: "Date", internal: "created_at", code: (row, data) => formatTimestamp(data)},
    ];

    useEffect(() => {
        axiosInstance.get('stock_historic/').then((response) => {
            setRows(response.data.results);
        });
    }, []);

    return (
        <div>
            <div className={styles.mainContainer}>
                <AdminHeader/>
                <AdminSidebar/>

                <div className={`${styles.container} me-4 pb-5`}>
                    <TableView
                        fetchUrl={'stock_historic'}
                        pull={setRows}
                        noPagination={false}
                        title={'stock History'}
                        cols={cols}
                        rows={rows}
                        withIndex={true}
                        noCreate={true}
                    />
                </div>
            </div>
        </div>
    );
}