import React, { useEffect, useRef, useState } from "react";
import styles from "./styles/inputs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

function TextInput({
  name,
  internalName,
  placeholder,
  form,
  setForm,
  hideTitle,
  type,
}) {
  const [formName, setFormName] = useState(internalName);

  useEffect(() => {
    if (!formName) {
      setFormName(name);
    }
  }, []);

  const setField = (value) => {
    setForm({
      ...form,
      [formName]: value,
    });
  };

  return (
    <div className="form-group">
      {!hideTitle && <div className={`${styles.inputLabel} mb-1`}>{name}</div>}
      <input
        type={type || "text"}
        className={`${styles.inputBorderDefault} form-control rounded-2`}
        id={name}
        placeholder={placeholder}
        value={form[formName]}
        onChange={(event) => setField(event.target.value)}
      />
    </div>
  );
}

function BigTextInput({ name, internalName, placeholder, form, setForm }) {
  const [formName, setFormName] = useState(internalName);

  useEffect(() => {
    if (!formName) {
      setFormName(name);
    }
  }, []);

  const setField = (value) => {
    setForm({
      ...form,
      [formName]: value,
    });
  };

  return (
    <div className="form-group mb-3">
      <div className={`${styles.inputLabel} mb-1`}>{name}</div>
      <textarea
        className={`${styles.inputBorderDefault} form-control rounded-2`}
        id={name}
        placeholder={placeholder}
        value={form[formName]}
        onChange={(event) => setField(event.target.value)}
      />
    </div>
  );
}

function SelectInput({
  name,
  internalName,
  options,
  form,
  setForm,
  noText,
  defaultValue,
}) {
  const [formName, setFormName] = useState(internalName);
  const [valueName, setValueName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [internalUpdate, setInternalUpdate] = useState(false);

  useEffect(() => {
    if (!internalUpdate) {
      setValueName(options[form[formName]]?.name);
    }
    setInternalUpdate(false);
  }, [form[formName]]);

  // old
  // useEffect(() => {
  //   if (!formName) {
  //     setFormName(name);
  //     setValueName(options[form[name]]?.name);
  //   } else {
  //     setValueName(options[form[formName]]?.name);
  //   }
  // }, []);
  useEffect(() => {
    if (defaultValue && defaultValue.id && defaultValue.name) {
      setValueName(defaultValue.name);
      setValue(defaultValue.name, defaultValue.id);
      setForm((prevForm) => ({
        ...prevForm,
        [formName]: defaultValue.id,
      }));
      return;
    } else if (!formName) {
      setFormName(name);
      setValueName(options[form[name]]?.name);
      return;
    } else {
      setValueName(options[form[formName]]?.name);
    }
  }, [defaultValue]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const setField = (value) => {
    setForm((prevForm) => ({
      ...prevForm,
      [formName]: value,
    }));
    setIsOpen(false);
  };

  const setValue = (name, value) => {
    setInternalUpdate(true);
    setValueName(name);
    setField(value);
  };

  return (
    <div className={`${styles.customSelect}`} ref={dropdownRef}>
      <div className={`${styles.inputLabel}`}>{name}</div>
      <div className={styles.dropdownButton} onClick={toggleDropdown}>
        {valueName || (noText ? "-" : "Select an option")}
      </div>
      <div className={`${styles.dropdownOptions} ${isOpen ? styles.show : ""}`}>
        {options &&
          options.map((option, index) => (
            <div
              key={index}
              className={`${
                form[formName] === option.id ? styles.selected : ""
              }`}
              onClick={() => setValue(option.name, option.id)}
            >
              {option.name}
            </div>
          ))}
      </div>
    </div>
  );
}

function RadioInput({ name, internalName, options, form, setForm }) {
  const [formName, setFormName] = useState(internalName);
  const [checked, setChecked] = useState(form[formName]);

  useEffect(() => {
    if (!formName) {
      setFormName(name);
    }
  }, []);

  useEffect(() => {
    setChecked(form[formName]);
  }, [form, formName]);

  useEffect(() => {
    setChecked(form[formName]);
  }, [form]);

  const setField = (value) => {
    setForm({
      ...form,
      [formName]: value,
    });
  };

  const handleRadioChange = (index) => {
    setChecked(index);
    setField(index);
  };

  return (
    <div className="form-group mb-3">
      <div className={`${styles.inputLabel} mb-1`}>{name}</div>
      <div className={styles.customRadio}>
        {options.map((option, index) => (
          <div key={index} className={styles.radioOption}>
            <input
              type="radio"
              id={`${name}-${index}`}
              name={name}
              checked={checked === index}
              onChange={() => handleRadioChange(index)}
            />
            <label htmlFor={`${name}-${index}`}>{option}</label>
          </div>
        ))}
      </div>
    </div>
  );
}

function FileInput({ name, internalName, form, setForm }) {
  const [formName, setFormName] = useState(internalName);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!formName) {
      setFormName(name);
    }
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    setForm({
      ...form,
      [formName]: event.target.files[0],
    });
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
    setForm({
      ...form,
      [formName]: event.dataTransfer.files[0],
    });
  };

  return (
    <div className={styles.fileInputOutsideContainer}>
      <div
        className={`${styles.fileInputContainer} py-4`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className={styles.fileInput} onClick={handleFileClick}>
          <div className={styles.uploadText}>
            <FontAwesomeIcon
              icon={faCloudArrowUp}
              size={"2xl"}
              color={"#6B7280"}
            />
            <div className={"d-flex"}>
              <div className={styles.defaultColorText}>Upload an image</div>or
              drag and drop
            </div>
            <div>PNG, JPG</div>
          </div>
        </div>
        <input
          type="file"
          accept=".png,.jpg,.jpeg"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        {selectedFiles.length > 0 && (
          <div className={`${styles.fileList}`}>
            {selectedFiles.map((file, index) => (
              <div className={styles.fileItem} key={index}>
                <img
                  className={styles.previewImage}
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

function DateInput({ name, internalName, form, setForm }) {
  const [formName, setFormName] = useState(internalName);

  useEffect(() => {
    if (!formName) {
      setFormName(name);
    }
  }, []);

  const setField = (value) => {
    setForm({
      ...form,
      [formName]: value,
    });
  };

  return (
    <div className="form-group mb-3">
      <div className={`${styles.inputLabel} mb-1`}>{name}</div>
      <input
        type={"date"}
        className={`${styles.inputBorderDefault} form-control rounded-2`}
        id={name}
        value={form[formName]}
        onChange={(event) => setField(event.target.value)}
      />
    </div>
  );
}

function PrimaryButton({ name, onClick, className, style }) {
  return (
    <div
      className={`${styles.primaryButton} ${className}`}
      style={style}
      onClick={onClick}
    >
      <div>{name}</div>
    </div>
  );
}

function SecondaryButton({ name, onClick }) {
  return (
    <div className={styles.secondaryButton} onClick={onClick}>
      <div>{name}</div>
    </div>
  );
}

function DangerButton({ name, onClick }) {
  return (
    <div className={styles.dangerButton} onClick={onClick}>
      <div>{name}</div>
    </div>
  );
}

export {
  TextInput,
  BigTextInput,
  SelectInput,
  RadioInput,
  FileInput,
  DateInput,
  PrimaryButton,
  SecondaryButton,
  DangerButton,
};
